import { useState, useEffect, useRef, React } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Table from "./tableKeyDetail/table_keyDetail";
import TableDevices from "./tableKeyDetail/table_keyDetail_OrderbyDevice";
import { Space, Select, Flex } from "antd";

export default () => {
  const { lang, proxy, auth } = useSelector((state) => state);
  let navigate = useNavigate();
  let CompanyName = localStorage.getItem("CompName");
  let baseHeight = localStorage.getItem("baseHeight");
  //console.log(baseHeight);
  //

  const goToPage = (label) => {
    navigate(`/` + label);
  };
  const [selectedView, setSelectedView] = useState(1);

  const switchView = (view) => {
    if (view == 1) return <Table />;
    else return <TableDevices />;
  };

  return (
    <div class="container-case-main">
      <div class="container-fluid">
        <div class="row column_title">
          <div class="col-md-12">
            <div class="page_title">
              <h4>{lang["ad dashboard"]}</h4>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="white_shd full">
              <div class="full graph_head">
                <Flex justify="space-between">
                  <Flex align="start">
                    <div class="heading1 margin_0">
                      <h5>
                        <label
                          class="pointer"
                          onClick={() => goToPage("Dashboard")}>
                          <label class="pointer">
                            <a title={lang["back"]}>
                              <i class=" fa fa-chevron-circle-left mr-1 nav-item nav-link"></i>
                            </a>
                          </label>

                          {lang["key detail"]}
                        </label>
                      </h5>
                    </div>
                  </Flex>
                  <Flex justify="flex-end">
                    <div class="heading1 margin_0">
                      <label style={{ paddingRight: "5px" }}>
                        {lang["switch view"]}
                      </label>
                      <Select
                        defaultValue="1"
                        style={{
                          width: 150,
                        }}
                        options={[
                          {
                            value: "1",
                            label: lang["key details"],
                          },
                          {
                            value: "2",
                            label: lang["device details"],
                          },
                        ]}
                        onChange={(value) => {
                          setSelectedView(value);
                        }}
                      />
                    </div>
                  </Flex>
                </Flex>
                <div class="row">
                  <div class="table_section padding_infor_info_layout_chart ">
                    {lang["customer name"] + ": "}

                    <label class="text-general">{CompanyName}</label>
                  </div>
                </div>
                <div class="row">
                  <div
                    class="table_section padding_infor_info_layout_chart "
                    style={{ minHeight: baseHeight - 60 + "px" }}>
                    {switchView(selectedView)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
