import KeyTable from "./table/key-table";
import ChartKey from "./chart/chart";
import { useState, useEffect, useRef, React, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { data } from "jquery";
import { number } from "echarts";
import { useNavigate } from "react-router-dom";
import { Tooltip, Pagination, Flex } from "antd";

export default () => {
  const { lang, proxy, auth, functions } = useSelector((state) => state);
  let langItemCheck = localStorage.getItem("lang");
  let langItem = localStorage.getItem("lang")
    ? localStorage.getItem("lang")
    : "Vi";
  const previousData = useRef(null);
  const [data, setData] = useState(null);
  let user = JSON.parse(localStorage.getItem("user"));
  const storedPwdString = localStorage.getItem("password_hash");
  const stringifiedUser = localStorage.getItem("user");
  const _user = JSON.parse(stringifiedUser) || {};
  const [dataDetail, setDataDetail] = useState({});
  const username =
    _user.username === "nhan.to" ? "Mylan Digital Solution" : _user.username;
  const _token = localStorage.getItem("_token");
  const [isActive, setIsActive] = useState(false);
  //
  const fetchCount = useRef(0);
  const fetchData = async () => {
    try {
      const requestBody = {
        checkUser: {
          username: "DIPES-TEAM",
          password: "a01555daf781180515dec9895c40f882",
        },
        checkCustomer: {
          username: "Mylan Digital Solution",
          password: "53e6086284353cb73d4979f08537d950", //storedPwdString,
        },
      };
      // Trước khi gọi API, đặt giá trị của tableDataProduct thành một mảng rỗng

      const response = await fetch(
        `${proxy()}/api/32BE0BDC56861DE9432457412CDB2E54`,
        {
          headers: {
            Authorization: _token,
            "content-type": "application/json",
          },
          method: "POST",
          body: JSON.stringify(requestBody),
        }
      );
      const resp = await response.json();
      if (JSON.stringify(resp.data) !== JSON.stringify(previousData.current)) {
        setData(resp.data); // Cập nhật state với dữ liệu mới
        previousData.current = resp.data; // Lưu dữ liệu mới vào ref
        fetchCount.current = 1; // Reset lại số lần fetch
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      fetchCount.current = 0;
    }
  };
  //giới hạn fetch data
  useEffect(() => {
    if (fetchCount.current < 5) {
      fetchData();
      fetchCount.current++;
    }
    //setPreviousValue(data);
  });

  //console.log("data", data);
  //Lấy dữ liệu cho list key
  const [_totalKey, _setTotalKey] = useState(0);
  const [_totalCase, _setTotalCase] = useState(0);
  const [_totalCaseDone, _setTotalCaseDone] = useState(0);
  const [_totalCaseProcessing, _setTotalCaseProcessing] = useState(0);
  const [_totalCaseFailed, _setTotalCaseFailed] = useState(0);
  const [heightChat, setHeightChat] = useState(0);
  useEffect(() => {
    //Tính tổng giá trị key
    _setTotalKey(
      data?.reduce((accumulator, currentObject) => {
        return accumulator + Number(currentObject.TTK);
      }, 0)
    );

    _setTotalCase(
      data?.reduce((accumulator, currentObject) => {
        return accumulator + Number(currentObject.TTC);
      }, 0)
    );
    _setTotalCaseDone(
      data?.reduce((accumulator, currentObject) => {
        return accumulator + Number(currentObject.TTCD);
      }, 0)
    );
    _setTotalCaseProcessing(
      data?.reduce((accumulator, currentObject) => {
        return accumulator + Number(currentObject.TTCP);
      }, 0)
    );
    _setTotalCaseFailed(
      data?.reduce((accumulator, currentObject) => {
        return accumulator + Number(currentObject.TTCF);
      }, 0)
    );
    //console.log(total);
    //Lưu giá trị độ cao của tiêu đề
    localStorage.setItem(
      "titleHeight",
      document.getElementById("titleitem").clientHeight
    );
  });
  //window.onresize;
  const windowHeightRespon = window.innerHeight;
  const calculateHeight = () => {
    let baseHeight = windowHeightRespon;
    let topbarHeight = localStorage.getItem("topbarHeight");
    let titleHeight = localStorage.getItem("titleHeight");
    // console.log("Chieu cao man hinh:", baseHeight)
    const maxHeightReduction = 96 - 40; // Giới hạn giảm chiều cao tối đa

    baseHeight = baseHeight - topbarHeight - titleHeight - 65;

    // Trừ đi imageAdjustment trước khi áp dụng giới hạn tối thiểu 588
    // Đảm bảo baseHeight không thấp hơn 588
    //baseHeight = Math.max(baseHeight, 585);
    // console.log("title", titleHeight);
    // console.log("base", baseHeight);
    // console.log("top", topbarHeight);
    localStorage.setItem("baseHeight", baseHeight);
    return `${baseHeight}px`;
  };
  //Go Page----------
  let navigate = useNavigate();
  const goToPage = (label) => {
    navigate(`/` + label);
  };
  //Search ----------
  const [searchValues, setSearchValues] = useState({});
  //console.log("s", data);
  const filteredData = useMemo(() => {
    if (Object.keys(searchValues).length === 0) {
      return data;
    }
    return data?.filter((row) =>
      Object.entries(searchValues).every(([key, value]) =>
        row[key]?.toString().toLowerCase().includes(value?.toLowerCase())
      )
    );
  }, [searchValues, data]);
  //console.log("da", filteredData);
  //console.log("a", data);
  const [inputValues, setInputValues] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 15;
  const indexOfLast = currentPage * rowsPerPage;
  const indexOfFirst = indexOfLast - rowsPerPage;
  const current = filteredData?.slice(indexOfFirst, indexOfLast);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const totalPages = Math.ceil(filteredData?.length / rowsPerPage);
  const mappedfilter = filteredData?.map((item) => ({
    col1: item["CusN"],
    col2: item["TTK"],
    col3: item["TTC"],
    col4: item["TTCP"],
    col5: item["TTCD"],
    col6: item["TTCF"],
  }));
  //console.log(mappedfilter);
  const setvalue = (value1, value2, value3, value4) => {
    localStorage.setItem("totalcase", value1);
    localStorage.setItem("totalcaseprocessing", value2);
    localStorage.setItem("totalcasedone", value3);
    localStorage.setItem("totalcasefailed", value4);
  };
  const setTotalKey = (value) => {
    localStorage.setItem("totalkey", value);
  };
  const [selectedRowId, setSelectedRowId] = useState(null);
  const handleRowClick = (rowId, labelRe) => {
    setSelectedRowId(rowId);

    //console.log("Row Clicked:", rowId + " " + labelRe);
    //Lưu tên công ty xuống
    localStorage.setItem("CompName", rowId);
    //tùy theo chọn cái gì thì lụm cái đó
    switch (labelRe) {
      case "Key":
        //console.log("Key");
        goToPage("KeyDetail");
        break;
      case "Case":
        //console.log("Case");
        goToPage("CaseDetail");
        localStorage.setItem("Key", "CaseDetail");
        break;
      case "Case_Processing":
        //console.log("Case_Processing");
        goToPage("CaseDetail");
        localStorage.setItem("Key", "CaseProcessing");
        break;
      case "Case_Done":
        //console.log("Case_Done");
        goToPage("CaseDetail");
        localStorage.setItem("Key", "CaseDone");
        break;
      case "Case_Failed":
        //console.log("Case_Done");
        goToPage("CaseDetail");
        localStorage.setItem("Key", "CaseFailed");
        break;
    }
  };

  const handleInputChange = (fomular_alias, value) => {
    setInputValues((prevValues) => ({
      ...prevValues,
      [fomular_alias]: value,
    }));
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === 13 || event.keyCode === 8) {
      handleSearchClick();
    }
  };

  const handleSearchClick = () => {
    setCurrentPage(1);
    setSearchValues(inputValues);
    //setData(filteredData);
    setIsActive(true);
    setTimeout(() => {
      setIsActive(false);
    }, 300);
  };
  const lastRowFitHeight = () => {
    return "calc(40vh - 20px)";
  };
  //
  const getGeneralInfo = () => {
    return (
      <div class="row">
        <label class="text-general-nonebold">Total Customer</label>
        <label class="text-totalcase">{param ? param.totalcustomer : 0}</label>
        <label class="text-general-nonebold">(Keys</label>
        <label class="text-totalcaseprocessing">
          {param ? param.totalkey : 0}{" "}
        </label>
        <label class="text-general-nonebold">|Total Case</label>
        <label class="text-totalcase">{param ? param.totalcase : 0}</label>
        <label class="text-general-nonebold">,in Processing</label>
        <label class="text-totalcaseprocessing">
          {param ? param.totalcaseprocessing : 0}{" "}
        </label>
        <label class="text-general-nonebold">,Resolved</label>
        <label class="text-totalcasedone">
          {param ? param.totalcasedone : 0}
        </label>
        <label class="text-general-nonebold">,Cancel</label>
        <label class="text-totalcasefailed">
          {param ? param.totalcasefailed : 0}
        </label>
        )
      </div>
    );
  };

  const param = {
    totalcustomer: data?.length,
    totalkey: _totalKey,
    totalcase: _totalCase,
    totalcaseprocessing: _totalCaseProcessing,
    totalcasedone: _totalCaseDone,
    totalcasefailed: _totalCaseFailed,
  };
  return (
    <div class="midde_cont">
      <div class="container-fluid">
        <div class="row column_title" id="titleitem">
          <div class="col-md-12">
            <div class="page_title">
              <h4>{lang["ad dashboard"]}</h4>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <div class="white_shd full">
              <div class="full graph_head">
                <div class="row">
                  <h4>TABLE DATA</h4>
                </div>
              </div>
              <div
                class="table_section padding_infor_info_layout_chart "
                id="maintable"
                style={{ minHeight: calculateHeight() }}>
                <div class="d-flex mb-1 mt-1"></div>
                {getGeneralInfo()}
                <div class="table-responsive">
                  <table className="table">
                    <thead>
                      <tr class="color-tr sticky-header">
                        <th
                          class="font-weight-bold "
                          style={{ width: "50px", minWidth: "50px" }}
                          scope="col">
                          {lang["log.no"]}
                        </th>
                        <th class="font-weight-bold align-center">
                          Company Name
                        </th>
                        <th class="font-weight-bold align-center">Total Key</th>
                        <th class="font-weight-bold align-center">
                          Total Case
                        </th>
                        <th class="font-weight-bold align-center">
                          Total Case in Processing
                        </th>
                        <th class="font-weight-bold align-center">
                          Total Case Resolved
                        </th>
                        <th class="font-weight-bold align-center">
                          Total Case Cancel
                        </th>
                      </tr>
                      <tr class="input-search-cus">
                        <th
                          style={{ borderBottom: "0.5px solid #dee2e6" }}></th>
                        <th style={{ borderBottom: "0.5px solid #dee2e6" }}>
                          <input
                            type="search"
                            className="form-control "
                            // value={
                            //   inputValues["CompanyName"]||""
                            // }
                            onChange={(e) =>
                              handleInputChange("CusN", e.target.value)
                            }
                            onKeyDown={handleKeyDown}
                          />
                        </th>
                        <th style={{ borderBottom: "0.5px solid #dee2e6" }}>
                          <input
                            type="search"
                            className="form-control "
                            // value={
                            //   inputValues["TotalKey"] || ""
                            // }
                            onChange={(e) =>
                              handleInputChange("TTK", e.target.value)
                            }
                            onKeyDown={handleKeyDown}
                          />
                        </th>
                        <th style={{ borderBottom: "0.5px solid #dee2e6" }}>
                          <input
                            type="search"
                            className="form-control "
                            // value={
                            //   inputValues["TotalCase"] || ""
                            // }
                            onChange={(e) =>
                              handleInputChange("TTC", e.target.value)
                            }
                            onKeyDown={handleKeyDown}
                          />
                        </th>
                        <th style={{ borderBottom: "0.5px solid #dee2e6" }}>
                          <input
                            type="search"
                            className="form-control "
                            // value={
                            //   inputValues["TotalProcessing"] || ""
                            // }
                            onChange={(e) =>
                              handleInputChange("TTCP", e.target.value)
                            }
                            onKeyDown={handleKeyDown}
                          />
                        </th>
                        <th style={{ borderBottom: "0.5px solid #dee2e6" }}>
                          <input
                            type="search"
                            className="form-control "
                            // value={
                            //   inputValues["TotalDone"] || ""
                            // }
                            onChange={(e) =>
                              handleInputChange("TTCD", e.target.value)
                            }
                            onKeyDown={handleKeyDown}
                          />
                        </th>
                        <th style={{ borderBottom: "0.5px solid #dee2e6" }}>
                          <input
                            type="search"
                            className="form-control "
                            // value={
                            //   inputValues["TotalFailed"] || ""
                            // }
                            onChange={(e) =>
                              handleInputChange("TTCF", e.target.value)
                            }
                            onKeyDown={handleKeyDown}
                          />
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {!mappedfilter ? (
                        <tr>
                          <td
                            class=""
                            colspan={7}
                            style={{ textAlign: "center" }}>
                            <div>
                              <img
                                width={48}
                                className="mb-1"
                                src="/images/icon/load.gif"
                                alt="Loading..."></img>
                            </div>
                          </td>
                        </tr>
                      ) : (
                        mappedfilter.map((row, index) => (
                          <tr key={row.id}>
                            <td class="align-center">{index + 1}</td>
                            <td class="table-td-product-pl-5">
                              {/**Tên company */}
                              <span class="table-td-product-pl-6">
                                {row.col1}
                              </span>
                            </td>

                            <td class="table-td-product-pl-5">
                              {/**Tổng số key đã tạo */}
                              <button
                                class="btn btn-totalkey"
                                onClick={() => {
                                  handleRowClick(row.col1, "Key");
                                  setTotalKey(row.col2);
                                }}>
                                <span class="table-td-product-pl-6">
                                  {row.col2}
                                </span>
                              </button>
                            </td>

                            <td class="table-td-product-pl-5">
                              {/**Tổng số case đã tạo */}
                              <button
                                class="btn btn-totalcase"
                                onClick={() => {
                                  handleRowClick(row.col1, "Case");
                                  setvalue(
                                    row.col3,
                                    row.col4,
                                    row.col5,
                                    row.col6
                                  );
                                }}>
                                <span class="table-td-product-pl-6">
                                  {row.col3}
                                </span>
                              </button>
                            </td>
                            <td class="table-td-product-pl-5">
                              {/**Tổng case đang thực hiện */}
                              <button
                                class="btn btn-totalcaseprocessing"
                                onClick={() =>
                                  handleRowClick(row.col1, "Case_Processing")
                                }>
                                <span class="table-td-product-pl-6">
                                  {row.col4}
                                </span>
                              </button>
                            </td>
                            <td class="table-td-product-pl-5">
                              {/**Tổng case đã thực hiện */}
                              <button
                                class="btn btn-totalcasedone"
                                onClick={() =>
                                  handleRowClick(row.col1, "Case_Done")
                                }>
                                <span class="table-td-product-pl-6">
                                  {row.col5}
                                </span>
                              </button>
                            </td>
                            <td class="table-td-product-pl-5">
                              {/**Tổng case đã thất bại */}
                              <button
                                class="btn btn-totalcasefailed"
                                onClick={() =>
                                  handleRowClick(row.col1, "Case_Failed")
                                }>
                                <span class="table-td-product-pl-6">
                                  {row.col6}
                                </span>
                              </button>
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="bottom-div">
                <Flex align="center" gap="small" justify="center">
                  <Pagination
                    total={data?.length}
                    showTotal={(total, range) =>
                      `${range[0]}-${range[1]} of ${total} items`
                    }
                    onChange={(value) => {
                      paginate(value);
                    }}
                    defaultPageSize={rowsPerPage}
                    defaultCurrent={1}
                    showSizeChanger={false}
                  />
                </Flex>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
