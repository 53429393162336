import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import $ from "jquery";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMaximize,
  faMinimize,
  faDownload,
  faCompress,
  faChartBar,
  faUsersGear,
  faHouse,
  faLockOpen,
  faCaretRight,
  faCaretLeft,
  faChevronUp,
  faChevronDown,
  faPlusCircle,
  faCirclePlus,
  faCirclePlay,
  faRectangleXmark,
  faCircle,
  faCircleXmark,
  faAngleDown,
  faEllipsisVertical,
  faPlusSquare,
  faPaperPlane,
  faPaperclip,
  faAngleLeft,
  faClose,
  faNewspaper,
  faCaretDown,
  faChartSimple,
} from "@fortawesome/free-solid-svg-icons";

export default () => {
  const { proxy, lang, pages, functions, sidebar } = useSelector(
    (state) => state
  );
  const { openTab } = functions;
  const stringifiedUser = localStorage.getItem("user");
  const user = JSON.parse(stringifiedUser) || {};
  const [activeLink, setActiveLink] = useState("/");
  const [uis, setUis] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const sideBar = sidebar ? sidebar.sidebar : null;
  const [isExpanded, setIsExpanded] = useState(true);

  const [expandedMenus, setExpandedMenus] = useState(() => {
    // Đọc giá trị từ localStorage và parse nó, hoặc trả về một đối tượng mặc định nếu không có
    const savedMenus = localStorage.getItem("expandedMenus");
    return savedMenus ? JSON.parse(savedMenus) : {};
  });

  useEffect(() => {
    const savedState = localStorage.getItem("menuExpanded");
    if (savedState !== null) {
      setIsExpanded(savedState === "true");
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("menuExpanded", isExpanded ? "true" : "false");
  }, [isExpanded]);

  const toggleMenu = (menuId) => (event) => {
    event.preventDefault();
    setExpandedMenus((prevState) => {
      const newState = {
        ...prevState,
        [menuId]: !prevState[menuId],
      };
      // Cập nhật localStorage với trạng thái mới
      localStorage.setItem("expandedMenus", JSON.stringify(newState));
      return newState;
    });
  };
  const toggleMenuAdmin = (event) => {
    event.preventDefault();
    setIsExpanded((prevState) => !prevState);
  };

  const OpenTab = (url) => {
    window.location.href = `/fetch/${url}`;
    // window.location.href = `tables`;
  };

  // useEffect(() => {
  //    if (window.innerWidth < 1200) {
  //       $('#sidebar').toggleClass('active');
  //    }
  // }, [window.location.href])

  return (
    <nav id="sidebar" class="">
      <div class="sidebar_blog_1">
        <div class="sidebar-header">
          <div class="logo_section">
            <a href="#">
              <img
                class="logo_icon img-responsive"
                src="/images/logo/logo_icon.png"
                alt="#"
              />
            </a>
          </div>
        </div>
        <div class="sidebar_user_info_custom">
          <div class="icon_setting"></div>
          <div class="user_profle_side">
            <div class="logo_section">
              <a href="#">
                <img
                  class="img-responsive"
                  src="/images/logo/logo.png"
                  alt="#"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="sidebar_blog_2">
        {/* <h4>{lang["general"]}</h4> */}
        <ul class="list-unstyled components">
          {/* <li className="navbar-item">
                  <NavLink to="/" activeClassName="nav-active">
                     <i class="fa fa-home icon-home"></i>
                     <span>{lang["home"]}</span>
                  </NavLink>
               </li> */}
          {user.role === "uad" ? (
            <li className="navbar-item">
              <NavLink to="/import" activeClassName="nav-active">
                <i class="fa fa-upload size-24 icon-import-nav"></i>
                <span>Import</span>
              </NavLink>
            </li>
          ) : null}
          {user.role === "uad" ? (
            <li className="navbar-item">
              <NavLink to="/active" activeClassName="nav-active">
                <i class="fa fa-key purple_color3"></i>
                <span>{lang["activation"]}</span>
              </NavLink>
            </li>
          ) : null}

          {user.role === "ad" || user.role === "uad" ? (
            <li className="navbar-item">
              <NavLink to="/users" activeClassName="nav-active">
                <i class="fa fa-users icon-user"></i>
                <span>{lang["accounts manager"]}</span>
              </NavLink>
            </li>
          ) : null}

          {user.role === "ad" || user.role === "uad" ? (
            <li className="navbar-item">
              <NavLink to="/privileges" activeClassName="nav-active">
                <i class="fa fa-lock icon-privileges"></i>

                <span>{lang["privileges manager"]}</span>
              </NavLink>
            </li>
          ) : null}

          {user.role === "ad" || user.role === "uad" ? (
            <li>
              <a
                href="#dashb"
                onClick={toggleMenuAdmin}
                aria-expanded="false"
                class="dropdown-toggle">
                <i class="fa fa-dashboard yellow_color"></i>
                <span>{lang["data management"]}</span>
                <i class="fa "></i>
              </a>
              <ul
                className={`collapse list-unstyled ${
                  isExpanded ? "show" : ""
                } ${
                  user.role === "uad" ||
                  user.role === "ad" ||
                  user.role === "mds_ad"
                    ? "scrollable"
                    : "scrollable-admin"
                } `}
                id="dashboard"></ul>
            </li>
          ) : null}
          {/* <NavbarRender data ={data_cpn.data} /> */}

          {/* Feedback */}

          {user.role === "pd" || user.role === "mds_ad" ? (
            <>
              {pages &&
                pages.map((ui, index) =>
                  ui.status && ui.title === "HOME" ? (
                    <li key={index} className="navbar-item">
                      <NavLink
                        to={`/page${ui.url}`}
                        activeClassName="nav-active">
                        <FontAwesomeIcon
                          icon={faHouse}
                          className={`size-20 mr-3`}
                        />
                        <span class={!sideBar && "navbar-close"}>
                          {ui.title}
                        </span>
                      </NavLink>
                    </li>
                  ) : null
                )}
            </>
          ) : null}
          {/**Page Dashboard Admin */}
          {user.role === "mds_ad" || user.role === "uad" ? (
            <li className="navbar-item">
              <NavLink to={`/Dashboard`} activeClassName="nav-active">
                <FontAwesomeIcon
                  icon={faChartSimple}
                  className={`blue_controller size-20 mr-3`}
                />
                <span class={!sideBar && "navbar-close"}>DASHBOARD</span>
              </NavLink>
            </li>
          ) : null}
          {user.role === "pd" ||
          user.role === "uad" ||
          user.role === "mds_ad" ? (
            <>
              <li className="navbar-item">
                <NavLink to="/technical" activeClassName="nav-active">
                  <FontAwesomeIcon
                    icon={faUsersGear}
                    className={`icon-technical size-20 mr-3`}
                  />
                  <span>TECHNICAL SERVICE</span>
                </NavLink>
              </li>

              <li>
                <a
                  href="#dashboardx"
                  onClick={toggleMenu}
                  aria-expanded="false">
                  <FontAwesomeIcon
                    icon={faLockOpen}
                    className={`yellow_color size-20 mr-3`}
                  />
                  <span>ONLINE ACTIVATION</span>
                  {isExpanded ? (
                    <FontAwesomeIcon
                      icon={faCaretDown}
                      className={`size-18 ml-2 ${
                        sideBar ? "" : "hidden-title"
                      }`}
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faCaretRight}
                      className={`size-18 ml-2 ${
                        sideBar ? "" : "hidden-title"
                      }`}
                    />
                  )}
                </a>
                <ul
                  className={`collapse list-unstyled ${
                    isExpanded ? "show" : "show"
                  } scrollable`}
                  id="dashboardx">
                  {pages &&
                    pages.map((ui, index) =>
                      ui.status && ui.title !== "HOME" ? (
                        <li key={index} className="navbar-item">
                          <NavLink
                            to={`/page${ui.url}`}
                            activeClassName="nav-active">
                            <FontAwesomeIcon
                              icon={faNewspaper}
                              className={`size-20 mr-3 `}
                            />
                            <span>{ui.title}</span>
                          </NavLink>
                        </li>
                      ) : null
                    )}
                </ul>
              </li>
            </>
          ) : null}

          {/* Active online */}
          {/* <div class={`scrollable_user ${user.role === "pd" ? 'no_shadow' : ''}`}>
                  {user.role === "pd" ? (
                     // <li>
                     //    {pages && pages.map(ui => (
                     //       ui.status  ? (
                     //          <li className="navbar-item">

                     //             <NavLink to={`/page${ui.url}`} activeClassName="nav-active">
                     //                <i class="fa fa-newspaper-o"></i>
                     //                <span>{ui.title}</span>
                     //             </NavLink>
                     //          </li>
                     //       ) : null
                     //    ))}
                     // </li>

                     //demo
                     <>
                        <li>

                           {pages && pages.map((ui, index) => (
                              ui.status && ui.ui_id === 3406 ? (
                                 <li key={index} className="navbar-item">
                                    <NavLink to={`/page${ui.url}`} activeClassName="nav-active">
                                       <i class="fa fa-newspaper-o"></i>
                                       <span>{ui.title}</span>
                                    </NavLink>
                                 </li>
                              ) : null
                           ))}

                        </li>
                        <li>
                           <a href="#dashboard" onClick={toggleMenu('dashboardMenu1')} aria-expanded="false" class="dropdown-toggle">
                              <i class="fa fa-dashboard yellow_color"></i>
                              <span>Quản lý nhà phân phối</span>
                              <i class="fa "></i>
                           </a>
                           <ul className={`collapse list-unstyled ${expandedMenus['dashboardMenu1'] ? 'show' : ''} ${user.role === "uad" ? "scrollable" : "scrollable-admin"} `} id="dashboard">
                              {pages && pages.map((ui, index) => (
                                 ui.status && ui.ui_id === 3416 || ui.ui_id === 3438 || ui.ui_id === 3459 || ui.ui_id === 3427 ? (
                                    <li key={index} className="navbar-item">
                                       <NavLink to={`/page${ui.url}`} activeClassName="nav-active">
                                          <i class="fa fa-newspaper-o"></i>
                                          <span>{ui.title}</span>
                                       </NavLink>
                                    </li>
                                 ) : null
                              ))}
                           </ul>
                        </li>

                        <li>
                           <a href="#dashboard" onClick={toggleMenu('dashboardMenu2')} aria-expanded="false" class="dropdown-toggle">
                              <i class="fa fa-briefcase blue1_color"></i>
                              <span>Sản phẩm</span>
                              <i class="fa "></i>
                           </a>
                           <ul className={`collapse list-unstyled ${expandedMenus['dashboardMenu2'] ? 'show' : ''} ${user.role === "uad" ? "scrollable" : "scrollable-admin"} `} id="dashboard">
                              {pages && pages.map((ui, index) => (
                                 ui.status && ui.ui_id === 3469 || ui.ui_id === 3499 ? (
                                    <li key={index} className="navbar-item">
                                       <NavLink to={`/page${ui.url}`} activeClassName="nav-active">
                                          <i class="fa fa-newspaper-o"></i>
                                          <span>{ui.title}</span>
                                       </NavLink>
                                    </li>
                                 ) : null
                              ))}
                           </ul>
                        </li>
                        <li>
                           <a href="#dashboard" onClick={toggleMenu('dashboardMenu3')} aria-expanded="false" class="dropdown-toggle">
                              <i class="fa fa-cubes red_color"></i>
                              <span>Quản lý kho</span>
                              <i class="fa "></i>
                           </a>
                           <ul className={`collapse list-unstyled ${expandedMenus['dashboardMenu3'] ? 'show' : ''} ${user.role === "uad" ? "scrollable" : "scrollable-admin"} `} id="dashboard">
                              {pages && pages.map((ui, index) => (
                                 ui.status && ui.ui_id === 3543 ? (
                                    <li key={index} className="navbar-item">
                                       <NavLink to={`/page${ui.url}`} activeClassName="nav-active">
                                          <i class="fa fa-newspaper-o"></i>
                                          <span>{ui.title}</span>
                                       </NavLink>
                                    </li>
                                 ) : null
                              ))}
                              {pages && pages.map((ui, index) => (
                                 ui.status && ui.ui_id === 3584 ? (
                                    <li key={index} className="navbar-item">
                                       <NavLink to={`/page${ui.url}`} activeClassName="nav-active">
                                          <i class="fa fa-newspaper-o"></i>
                                          <span>{ui.title}</span>
                                       </NavLink>
                                    </li>
                                 ) : null
                              ))}

                              {pages && pages.map((ui, index) => (
                                 ui.status && ui.ui_id === 3604 ? (
                                    <li key={index} className="navbar-item">
                                       <NavLink to={`/page${ui.url}`} activeClassName="nav-active">
                                          <i class="fa fa-newspaper-o"></i>
                                          <span>{ui.title}</span>
                                       </NavLink>
                                    </li>
                                 ) : null
                              ))}
                              {pages && pages.map((ui, index) => (
                                 ui.status && ui.ui_id === 3553 || ui.ui_id === 3563 || ui.ui_id === 3573 ? (
                                    <li key={index} className="navbar-item">
                                       <NavLink to={`/page${ui.url}`} activeClassName="nav-active">
                                          <i class="fa fa-newspaper-o"></i>
                                          <span>{ui.title}</span>
                                       </NavLink>
                                    </li>
                                 ) : null
                              ))}
                           </ul>
                        </li>


                        <li>
                           <a href="#dashboard" onClick={toggleMenu('dashboardMenu4')} aria-expanded="false" class="dropdown-toggle">
                              <i class="fa fa-clock-o purple_color2"></i>
                              <span>Quản lý sản xuất</span>
                              <i class="fa "></i>
                           </a>
                           <ul className={`collapse list-unstyled ${expandedMenus['dashboardMenu4'] ? 'show' : ''} ${user.role === "uad" ? "scrollable" : "scrollable-admin"} `} id="dashboard">
                              {pages && pages.map((ui, index) => (
                                 ui.status && ui.ui_id === 3479 || ui.ui_id === 3531 ? (
                                    <li key={index} className="navbar-item">
                                       <NavLink to={`/page${ui.url}`} activeClassName="nav-active">
                                          <i class="fa fa-newspaper-o"></i>
                                          <span>{ui.title}</span>
                                       </NavLink>
                                    </li>
                                 ) : null
                              ))}
                           </ul>
                        </li>
                        <li>
                           <a href="#dashboard" onClick={toggleMenu('dashboardMenu5')} aria-expanded="false" class="dropdown-toggle">
                              <i class="fa fa-gears"></i>
                              <span>Cấu hình chung</span>
                              <i class="fa "></i>
                           </a>
                           <ul className={`collapse list-unstyled ${expandedMenus['dashboardMenu5'] ? 'show' : ''} ${user.role === "uad" ? "scrollable" : "scrollable-admin"} `} id="dashboard">
                              {pages && pages.map((ui, index) => (
                                 ui.status && ui.ui_id === 3489 ? (
                                    <li key={index} className="navbar-item">
                                       <NavLink to={`/page${ui.url}`} activeClassName="nav-active">
                                          <i class="fa fa-newspaper-o"></i>
                                          <span>{ui.title}</span>
                                       </NavLink>
                                    </li>
                                 ) : null
                              ))}
                           </ul>
                        </li>
                     </>



                  ) : null}

               </div>

               {user.role === "ad" || user.role === "uad" ? (

               </div> */}
          {user.role === "uad" ? (
            <>
              <li className="navbar-item">
                <NavLink to="/diagram_db" activeClassName="nav-active">
                  <i class="fa fa-database pointer icon-database"></i>
                  <span>{lang["diagram"]}</span>
                </NavLink>
              </li>
              <li className="navbar-item">
                <NavLink
                  to="/sitemap"
                  onClick={() => {
                    openTab("/sitemap");
                  }}
                  activeClassName="nav-active">
                  <i class="fa fa-sitemap"></i>
                  <span>{lang["site map"]}</span>
                </NavLink>
              </li>
            </>
          ) : null}
          {/* {user.role === "uad" ? (
                  <li className="navbar-item">
                     <NavLink to="/logs" activeClassName="nav-active">
                        <i class="fa fa-shield"></i>
                        <span>{lang["log.title"]}</span>
                     </NavLink>
                  </li>
               ) : null} */}

          {user.role === "uad" || user.role === "mds_ad" ? (
            <>
              <li className="navbar-item">
                <NavLink to="/logs" activeClassName="nav-active">
                  <i class=" fa fa-shield orange_color2"></i>
                  <span>{lang["log.title"]}</span>
                </NavLink>
              </li>
            </>
          ) : null}
        </ul>
      </div>
      <div class="footer-custom ">
        <p>&copy; 2023 - Designed by Mylan Group </p>
      </div>
    </nav>
  );
};
