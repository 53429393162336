import copy from "copy-to-clipboard";
import { useState, useEffect, useRef, React } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

function Active_Helpdesk() {
  const [isActivated, setIsActivated] = useState(false);
  const { lang, proxy, auth, functions } = useSelector((state) => state);
  const { openTab, renderDateTimeByFormat } = functions;
  const _token = localStorage.getItem("_token");
  const checkDetail = 1; //page.components?.[0]?.api_detail;
  const [inputValues, setInputValues] = useState({});
  const [searchValues, setSearchValues] = useState({});
  const [isActive, setIsActive] = useState(false);
  let totalcase = localStorage.getItem("totalcase");
  let totalcaseprocessing = localStorage.getItem("totalcaseprocessing");
  let totalcasedone = localStorage.getItem("totalcasedone");
  let totalcasefailed = localStorage.getItem("totalcasefailed");
  const handleSubmit = (e) => {
    e.preventDefault();
    setIsActivated(true);
    // TODO: Thêm mã để xử lý thông tin form và tạo mã kích hoạt
  };
  let navigate = useNavigate();
  const goToPage = (label) => {
    navigate(`/` + label);
  };
  const copyToClipboard = () => {
    const copyText = document.getElementById("activationKey");
    copyText.select();
    document.execCommand("copy");
    alert("Đã sao chép mã kích hoạt!");
  };
  const [isCopied, setIsCopied] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const handleInputChange = (fomular_alias, value) => {
    setInputValues((prevValues) => ({
      ...prevValues,
      [fomular_alias]: value,
    }));
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      handleSearchClick();
    }
  };

  const handleSearchClick = () => {
    setCurrentPage(1);
    setSearchValues(inputValues);

    setIsActive(true);
    setTimeout(() => {
      setIsActive(false);
    }, 300);
  };
  //
  const handleViewDetail = async (record) => {
    const cpn = {
      component_id: 1896,
      component_name: "KEYS",
      layout_id: 3,
      api_get: "/api/8C6CE27F524B4809948762F27B55BC35",
      api_post: "/ui/8FD3A675DA4846CA9ABBF936C0373817",
      api_put: "/ui/6C49D605C1EF47C3BA130A4DFC234864",
      api_delete: "/ui/E76A026765284F88AEE00081B4E3AB90",
      api_search: "/search/845E807944524E1AB5E79C917E11E5A7",
      api_export: "/export/6FD48D8D40EB4CD4AD84B4BD3A9AD7F1",
      api_import: "/import/133099ADFCE945939BE343DDEAAA7F90",
      api_detail: "/d/ED62F146466648A18B9F2F82928B1A5C",
    };
    const { api_detail } = cpn;
    if (api_detail != undefined) {
      const id_str = api_detail.split("/")[2];

      const response = await new Promise((resolve, reject) => {
        fetch(`${proxy()}/apis/api/${id_str}/input_info`, {
          headers: {
            Authorization: _token,
          },
        })
          .then((res) => res.json())
          .then((res) => {
            const { data, success, content } = res;
            // console.log(res)
            if (success) {
              // console.log("succcess", data)
              // setDataTables(data.tables)
              // setDataFields(data.body)
            }
            resolve(res);
          });
      });
      const { success, data } = response;

      if (success) {
        const stringifiedParams = data.body
          .map((param) => {
            const { fomular_alias } = param;
            return record[fomular_alias];
          })
          .join("/");
        goToPage(
          `CaseDetailViewer/case/detail-case/${id_str}/${stringifiedParams}`
        );
      }
    } else {
      Swal.fire({
        title: lang["faild"],
        text: lang["not found"],
        icon: "error",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  };
  //mở trang tạm
  const viewpageSample = () => {
    handleViewDetail(SampleData[0]);
  };
  //Dữ liệu sample
  const SampleData = [
    {
      "1I": "1",
      "23T": "Controller",
      "2U": "28aacb-bcc2ad4d-06034fe1-c9f88c",
      "11S": "M0253J3P013N030",
      "15A": "2e562f-26caadf6-760a6d09-a997ae",
      "5C": "Mylan Digital Solution",
      "1R": "",
      "9D": "020124",
      "2R": "1",
      "19M": "TA30+",
      "5P": "true",
      "3H": "HP45",
      "6P": "",
      "3U": "MLG01028",
      "6C": "2024-01-02 20:18:50",
    },
    {
      "1I": "2",
      "23T": "Print head",
      "2U": "ed53ef-a5727b2c-10287dc4-75ad7f",
      "11S": "M0263J2P007N025",
      "15A": "55dac3-6fd1f037-4f58a938-1514de",
      "5C": "Mylan Digital Solution",
      "1R": "",
      "9D": "020124",
      "2R": "1",
      "19M": "LVDS Head",
      "5P": "false",
      "3H": "IUT308",
      "6P": "Rynan C",
      "3U": "MLG01028",
      "6C": "2024-01-02 20:18:50",
    },
    {
      "1I": "3",
      "23T": "Print head",
      "2U": "ec53f6-a17284d3-10287dfb-75927f",
      "11S": "M0263J2P006N025",
      "15A": "55d8c1-6fd370b7-7408a93a-1514de",
      "5C": "Mylan Digital Solution",
      "1R": "",
      "9D": "020124",
      "2R": "1",
      "19M": "LVDS Head",
      "5P": "false",
      "3H": "IUT308",
      "6P": "Rynan C",
      "3U": "MLG01028",
      "6C": "2024-01-02 20:18:50",
    },
    {
      "1I": "4",
      "23T": "Controller",
      "2U": "28aacb-bcc2ad4d-06034fe1-c9f88c",
      "11S": "M0253J3P011N030",
      "15A": "ae572f-26caadf6-760a6d09-a997ae",
      "5C": "Mylan Digital Solution",
      "1R": "",
      "9D": "020124",
      "2R": "2",
      "19M": "TA30+",
      "5P": "true",
      "3H": "HP45",
      "6P": "",
      "3U": "MLG01028",
      "6C": "2024-01-02 20:21:55",
    },
    {
      "1I": "5",
      "23T": "Print head",
      "2U": "ed53e5-a5727b2c-10777dfb-75ad7f",
      "11S": "M0263J1P008N050",
      "15A": "55dac1-6fd1f037-4f08a938-1514dc",
      "5C": "Mylan Digital Solution",
      "1R": "",
      "9D": "020124",
      "2R": "2",
      "19M": "LVDS Head",
      "5P": "false",
      "3H": "HP45",
      "6P": "Rynan C - India",
      "3U": "MLG01028",
      "6C": "2024-01-02 20:21:55",
    },
    {
      "1I": "6",
      "23T": "Print head",
      "2U": "ed53ef-2772fb2c-10147dfb-75127f",
      "11S": "M0263J1P007N050",
      "15A": "55d847-6f51703f-ed48a938-1514de",
      "5C": "Mylan Digital Solution",
      "1R": "",
      "9D": "020124",
      "2R": "2",
      "19M": "LVDS Head",
      "5P": "false",
      "3H": "HP45",
      "6P": "Rynan C - India",
      "3U": "MLG01028",
      "6C": "2024-01-02 20:21:55",
    },
    {
      "1I": "7",
      "23T": "Controller",
      "2U": "caaaf4-bcc2ad4d-06034e1e-c9f88c",
      "11S": "M0253J3P012N030",
      "15A": "2cd727-26caa5f6-760a6d1b-a9cfae",
      "5C": "Mylan Digital Solution",
      "1R": "",
      "9D": "020124",
      "2R": "1",
      "19M": "TA30+",
      "5P": "true",
      "3H": "HP45",
      "6P": "",
      "3U": "MLG01028",
      "6C": "2024-01-02 20:23:08",
    },
    {
      "1I": "8",
      "23T": "Print head",
      "2U": "ed53e4-a7727b2c-10757dfb-75ad7f",
      "11S": "M0263J2P005N025",
      "15A": "55dac0-6f51f037-4d08a938-1514dc",
      "5C": "Mylan Digital Solution",
      "1R": "",
      "9D": "020124",
      "2R": "1",
      "19M": "LVDS Head",
      "5P": "false",
      "3H": "IUT308",
      "6P": "Rynan C",
      "3U": "MLG01028",
      "6C": "2024-01-02 20:23:08",
    },
    {
      "1I": "9",
      "23T": "Print head",
      "2U": "ed53ef-a3727b2c-10287dc4-75ad7f",
      "11S": "M0263J2P008N025",
      "15A": "55dac3-6fd1f037-4958a938-1514de",
      "5C": "Mylan Digital Solution",
      "1R": "",
      "9D": "020124",
      "2R": "1",
      "19M": "LVDS Head",
      "5P": "false",
      "3H": "IUT308",
      "6P": "Rynan C",
      "3U": "MLG01028",
      "6C": "2024-01-02 20:23:08",
    },
    {
      "1I": "10",
      "23T": "Controller",
      "2U": "28aacb-bcc2ad4d-06034fe1-c9ff8c",
      "11S": "M0253J3P001N030",
      "15A": "2c562f-26caadf6-760a6d09-a98fae",
      "5C": "Mylan Digital Solution",
      "1R": "",
      "9D": "020124",
      "2R": "1",
      "19M": "TA30+",
      "5P": "true",
      "3H": "HP45",
      "6P": "",
      "3U": "MLG01028",
      "6C": "2024-01-02 20:27:19",
    },
    {
      "1I": "11",
      "23T": "Controller",
      "2U": "caaaf4-bcc2ad4d-06034e1e-c9f88c",
      "11S": "M0253J3P002N030",
      "15A": "acd72f-26caa5f6-760a6d1b-a9cfae",
      "5C": "Mylan Digital Solution",
      "1R": "",
      "9D": "020124",
      "2R": "1",
      "19M": "TA30+",
      "5P": "true",
      "3H": "HP45",
      "6P": "",
      "3U": "MLG01028",
      "6C": "2024-01-02 20:28:45",
    },
    {
      "1I": "12",
      "23T": "Controller",
      "2U": "28aacb-bcc2ad4d-06034fe1-c9f08c",
      "11S": "M0253J3P003N030",
      "15A": "2cd72f-26caadf6-760a6d09-a99fae",
      "5C": "Mylan Digital Solution",
      "1R": "",
      "9D": "020124",
      "2R": "1",
      "19M": "TA30+",
      "5P": "true",
      "3H": "HP45",
      "6P": "",
      "3U": "MLG01028",
      "6C": "2024-01-02 20:29:07",
    },
    {
      "1I": "13",
      "23T": "Print head",
      "2U": "ed53e4-a1727b2c-10157dfb-75ad7f",
      "11S": "M0263J1P003N050",
      "15A": "55dac0-6f517037-4b48a938-1514dc",
      "5C": "Mylan Digital Solution",
      "1R": "",
      "9D": "020124",
      "2R": "1",
      "19M": "LVDS Head",
      "5P": "false",
      "3H": "HP45",
      "6P": "Rynan C - India",
      "3U": "MLG01028",
      "6C": "2024-01-02 20:29:07",
    },
    {
      "1I": "14",
      "23T": "Print head",
      "2U": "ed53e5-a3727b2c-10157dfb-75ad7f",
      "11S": "M0263J1P004N050",
      "15A": "55dac1-6f517037-4948a938-1514dc",
      "5C": "Mylan Digital Solution",
      "1R": "",
      "9D": "020124",
      "2R": "1",
      "19M": "LVDS Head",
      "5P": "false",
      "3H": "HP45",
      "6P": "Rynan C - India",
      "3U": "MLG01028",
      "6C": "2024-01-02 20:29:07",
    },
    {
      "1I": "15",
      "23T": "Controller",
      "2U": "c8aacb-bcc2ad4d-06034e1e-c9f08c",
      "11S": "M0253J3P004N030",
      "15A": "aed72f-26caa776-760a6d1a-a997ae",
      "5C": "Mylan Digital Solution",
      "1R": "",
      "9D": "020124",
      "2R": "2",
      "19M": "TA30+",
      "5P": "true",
      "3H": "HP45",
      "6P": "",
      "3U": "MLG01028",
      "6C": "2024-01-02 20:29:40",
    },
    {
      "1I": "16",
      "23T": "Print head",
      "2U": "ed53e5-a37204d3-10767dfb-75ad7f",
      "11S": "M0263J1P002N050",
      "15A": "55dac1-6f537137-5650a938-1514dc",
      "5C": "Mylan Digital Solution",
      "1R": "",
      "9D": "020124",
      "2R": "2",
      "19M": "LVDS Head",
      "5P": "false",
      "3H": "HP45",
      "6P": "Rynan C - India",
      "3U": "MLG01028",
      "6C": "2024-01-02 20:29:40",
    },
    {
      "1I": "17",
      "23T": "Print head",
      "2U": "ec53fa-a5727b2c-10357de4-75ad7f",
      "11S": "M0263J1P001N050",
      "15A": "55dac1-6fd1f1bf-4f48a93a-1514dc",
      "5C": "Mylan Digital Solution",
      "1R": "",
      "9D": "020124",
      "2R": "2",
      "19M": "LVDS Head",
      "5P": "false",
      "3H": "HP45",
      "6P": "Rynan C - India",
      "3U": "MLG01028",
      "6C": "2024-01-02 20:29:40",
    },
    {
      "1I": "18",
      "23T": "Controller",
      "2U": "28aacb-bcc2ad4d-06034fe1-c9f88c",
      "11S": "M0253J3P005N030",
      "15A": "ae562f-26caadf6-760a6d09-a997ae",
      "5C": "Mylan Digital Solution",
      "1R": "",
      "9D": "020124",
      "2R": "1",
      "19M": "TA30+",
      "5P": "true",
      "3H": "HP45",
      "6P": "",
      "3U": "MLG01028",
      "6C": "2024-01-02 20:30:09",
    },
    {
      "1I": "19",
      "23T": "Controller",
      "2U": "c8aacb-bcc2ad4d-06034e1e-c9f08c",
      "11S": "M0253J3P006N030",
      "15A": "2ed72f-26caa776-760a6d1a-a997ae",
      "5C": "Mylan Digital Solution",
      "1R": "",
      "9D": "020124",
      "2R": "1",
      "19M": "TA30+",
      "5P": "true",
      "3H": "HP45",
      "6P": "",
      "3U": "MLG01028",
      "6C": "2024-01-02 20:30:37",
    },
    {
      "1I": "20",
      "23T": "Controller",
      "2U": "28aacb-bcc2ad4d-06034fe1-c9f08c",
      "11S": "M0253J3P007N030",
      "15A": "2ed727-26caadf6-760a6d09-a997ae",
      "5C": "Mylan Digital Solution",
      "1R": "",
      "9D": "020124",
      "2R": "1",
      "19M": "TA30+",
      "5P": "true",
      "3H": "HP45",
      "6P": "",
      "3U": "MLG01028",
      "6C": "2024-01-02 20:31:00",
    },
    {
      "1I": "21",
      "23T": "Print head",
      "2U": "ed53e4-a28d04d3-10297dc4-75ad7f",
      "11S": "M0263J2P003N025",
      "15A": "55dac0-6fd1f03f-5758a938-1514dc",
      "5C": "Mylan Digital Solution",
      "1R": "",
      "9D": "020124",
      "2R": "1",
      "19M": "LVDS Head",
      "5P": "false",
      "3H": "IUT308",
      "6P": "Rynan C",
      "3U": "MLG01028",
      "6C": "2024-01-02 20:31:00",
    },
    {
      "1I": "22",
      "23T": "Print head",
      "2U": "ed53ef-208dfb2c-10157dfb-75127f",
      "11S": "M0263J2P001N025",
      "15A": "55d847-6fd3f13f-ea10a938-1514de",
      "5C": "Mylan Digital Solution",
      "1R": "",
      "9D": "020124",
      "2R": "1",
      "19M": "LVDS Head",
      "5P": "false",
      "3H": "IUT308",
      "6P": "Rynan C",
      "3U": "MLG01028",
      "6C": "2024-01-02 20:31:00",
    },
    {
      "1I": "23",
      "23T": "Controller",
      "2U": "c8aacb-bcc2ad4d-06034e1e-c9f78c",
      "11S": "M0253J3P008N030",
      "15A": "2ed627-26caa776-760a6d1a-a987ae",
      "5C": "Mylan Digital Solution",
      "1R": "",
      "9D": "020124",
      "2R": "1",
      "19M": "TA30+",
      "5P": "true",
      "3H": "HP45",
      "6P": "",
      "3U": "MLG01028",
      "6C": "2024-01-02 20:31:27",
    },
    {
      "1I": "24",
      "23T": "Print head",
      "2U": "ed53e9-a68d7b2c-10157dfb-75ad7f",
      "11S": "M0263J2P004N025",
      "15A": "55dac1-6fd3f13f-4c10a938-1514de",
      "5C": "Mylan Digital Solution",
      "1R": "",
      "9D": "020124",
      "2R": "1",
      "19M": "LVDS Head",
      "5P": "false",
      "3H": "IUT308",
      "6P": "Rynan C",
      "3U": "MLG01028",
      "6C": "2024-01-02 20:31:27",
    },
    {
      "1I": "25",
      "23T": "Print head",
      "2U": "ed53e5-a28d04d3-10747dfb-75ad7f",
      "11S": "M0263J2P002N025",
      "15A": "55dac1-6f51f03f-5708a938-1514dc",
      "5C": "Mylan Digital Solution",
      "1R": "",
      "9D": "020124",
      "2R": "1",
      "19M": "LVDS Head",
      "5P": "false",
      "3H": "IUT308",
      "6P": "Rynan C",
      "3U": "MLG01028",
      "6C": "2024-01-02 20:31:27",
    },
    {
      "1I": "26",
      "23T": "Controller",
      "2U": "c8aacb-bcc2ad4d-06034e1e-c9f08c",
      "11S": "M0253J3P010N030",
      "15A": "aed62f-26caa776-760a6d1a-a99fae",
      "5C": "Mylan Digital Solution",
      "1R": "",
      "9D": "020124",
      "2R": "1",
      "19M": "TA30+",
      "5P": "true",
      "3H": "HP45",
      "6P": "",
      "3U": "MLG01028",
      "6C": "2024-01-02 20:31:57",
    },
    {
      "1I": "27",
      "23T": "Print head",
      "2U": "ed53ef-2372fb2c-10297dc4-75127f",
      "11S": "M0263J1P005N050",
      "15A": "55d847-6fd1f03f-e958a938-1514de",
      "5C": "Mylan Digital Solution",
      "1R": "",
      "9D": "020124",
      "2R": "1",
      "19M": "LVDS Head",
      "5P": "false",
      "3H": "HP45",
      "6P": "Rynan C - India",
      "3U": "MLG01028",
      "6C": "2024-01-02 20:31:57",
    },
    {
      "1I": "28",
      "23T": "Print head",
      "2U": "ed53e7-a48d7b2c-10157dfb-75ad7f",
      "11S": "M0263J1P006N050",
      "15A": "55dac3-6fd3f13f-4e10a938-1514dc",
      "5C": "Mylan Digital Solution",
      "1R": "",
      "9D": "020124",
      "2R": "1",
      "19M": "LVDS Head",
      "5P": "false",
      "3H": "HP45",
      "6P": "Rynan C - India",
      "3U": "MLG01028",
      "6C": "2024-01-02 20:31:57",
    },
    {
      "1I": "29",
      "23T": "Printer",
      "2U": "de7eaab5-c08a50e9-4a00d9e1-91cfd34a, f075468c-d93e8545-0e4b188a-759d7f1e",
      "11S": "M0283J2P001N025",
      "15A":
        "0b52d77d-75fd2625-75d48e53-8e150d92, 3a054c28-7117fa09-4c48c4ac-152456b4",
      "5C": "Mylan Digital Solution",
      "1R": "",
      "9D": "030124",
      "2R": "1",
      "19M": "B1040",
      "5P": "true",
      "3H": "IUT308",
      "6P": "Mylan B",
      "3U": "MLG00871",
      "6C": "2024-01-03 14:30:54",
    },
    {
      "1I": "225",
      "23T": "Controller",
      "2U": "35aad6-bcc2ad4d-39fd5601-f607ac",
      "11S": "D2243J2R011N015",
      "15A": "8cdda1-266aaef6-760b091b-a9c6fa",
      "5C": "Mylan Digital Solution",
      "1R": "",
      "9D": "150324",
      "2R": "1",
      "19M": "R20",
      "5P": "true",
      "3H": "HP45",
      "6P": "",
      "3U": "MLG01028",
      "6C": "2024-03-15 12:13:51",
    },
    {
      "1I": "226",
      "23T": "Print head",
      "2U": "ef53ec-a33e7aba-11f40210-75a37f",
      "11S": "M0243J1P002N040",
      "15A": "5d5ac8-7111700d-4950a0be-1584de",
      "5C": "Mylan Digital Solution",
      "1R": "",
      "9D": "150324",
      "2R": "1",
      "19M": "LVDS Head",
      "5P": "false",
      "3H": "Funai Biscayne",
      "6P": "Rynan A",
      "3U": "MLG01028",
      "6C": "2024-03-15 12:13:51",
    },
    {
      "1I": "272",
      "23T": "Controller",
      "2U": "5faaaa-c08a50e9-75ffc1e1-c86b4e",
      "11S": "KKKKKKKKKKKKKKK",
      "15A": "745df9-75dd2c21-75d5ea02-975d19",
      "5C": "Mylan Digital Solution",
      "1R": "",
      "9D": "260324",
      "2R": "2",
      "19M": "",
      "5P": "",
      "3H": "",
      "6P": "",
      "3U": "MLG01028",
      "6C": "2024-03-26 09:50:41",
    },
    {
      "1I": "292",
      "23T": "Print head",
      "2U": "ef53ef-a0c10545-11f40210-75a07f",
      "11S": "M0243J1P001N040",
      "15A": "5d5acb-7111700d-5550a0be-1584de",
      "5C": "Mylan Digital Solution",
      "1R": "",
      "9D": "280324",
      "2R": "1",
      "19M": "LVDS Head",
      "5P": "false",
      "3H": "Funai Biscayne",
      "6P": "Rynan A",
      "3U": "MLG01028",
      "6C": "2024-03-28 14:35:35",
    },
    {
      "1I": "301",
      "23T": "Controller",
      "2U": "35aad6-bcc2ad4d-39fd5601-f607ac",
      "11S": "D2243J2R007N015",
      "15A": "8edca1-266aaef6-760b091b-a9c6fa",
      "5C": "Mylan Digital Solution",
      "1R": "",
      "9D": "010424",
      "2R": "1",
      "19M": "R20",
      "5P": "true",
      "3H": "HP45",
      "6P": "",
      "3U": "MLG01028",
      "6C": "2024-04-01 16:18:47",
    },
    {
      "1I": "302",
      "23T": "Print head",
      "2U": "ed53ef-af7204d3-10157dfb-75ad7f",
      "11S": "TESTNVL",
      "15A": "55d843-6fd3f13f-5a10a938-1514de",
      "5C": "Mylan Digital Solution",
      "1R": "",
      "9D": "010424",
      "2R": "1",
      "19M": "",
      "5P": "",
      "3H": "",
      "6P": "",
      "3U": "MLG01028",
      "6C": "2024-04-01 16:18:47",
    },
    {
      "1I": "481",
      "23T": "Controller",
      "2U": "5faaaa-c08a50e9-75ffc1e1-b757b1",
      "11S": "K2283J9P001N990",
      "15A": "705f7d-75dd2c21-75d5ea02-975d19",
      "5C": "Mylan Digital Solution",
      "1R": "",
      "9D": "070524",
      "2R": "2",
      "19M": "",
      "5P": "",
      "3H": "",
      "6P": "",
      "3U": "MLG01028",
      "6C": "2024-05-07 14:56:16",
    },
  ];
  const handleCopy = (event) => {
    event.preventDefault();
    copy();

    setIsCopied(true);

    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };
  const lastRowFitHeight = () => {
    return "320px";
  };
  function formatPrinterKey(key) {
    return key.match(/.{1,4}/g).join("-");
  }

  const rawKey = "PRTA123B45PRTA123B45";
  const formattedKey = formatPrinterKey(rawKey);

  return (
    <div class="col-md-12">
      <div class="row">
        {/* <div class="col-md-3">
          <div class="row">
            <label class="text-general">Total Case</label>
            <label class="text-totalcase">{totalcase ? totalcase : 0}</label>
          </div>
        </div>
        <div class="col-md-3">
          <div class="row">
            <label class="text-general">Total Case Processing</label>
            <label class="text-totalcaseprocessing">
              {totalcaseprocessing ? totalcaseprocessing : 0}
            </label>
          </div>
        </div>
        <div class="col-md-3">
          <div class="row">
            <label class="text-general">Total Case Done</label>
            <label class="text-totalcasedone">
              {totalcasedone ? totalcasedone : 0}
            </label>
          </div>
        </div> */}
        <div class="col-md-3">
          <div class="row">
            <label class="text-general-nonebold">Total Case Cancel</label>
            <label class="text-totalcasefailed">
              {totalcasefailed ? totalcasefailed : 0}
            </label>
          </div>
        </div>
      </div>
      <div class="table-responsive">
        <>
          <table class="table table  ">
            <thead>
              <tr class="color-tr" style={{ height: "40px" }}>
                <th
                  class="font-weight-bold "
                  style={{ width: "50px", minWidth: "50px" }}
                  scope="col"
                >
                  {lang["log.no"]}
                </th>
                <th
                  class="font-weight-bold"
                  style={{ width: "200px" }}
                  scope="col"
                >
                  <div className="th-container">Tên Case</div>
                </th>
                <th
                  class="font-weight-bold"
                  style={{ width: "480px" }}
                  scope="col"
                >
                  <div className="th-container">Nội dung</div>
                </th>
                <th
                  class="font-weight-bold"
                  style={{ width: "220px" }}
                  scope="col"
                >
                  <div className="th-container">Thời gian tạo</div>
                </th>
                <th
                  class="font-weight-bold align-center"
                  style={{ width: "100px" }}
                  scope="col"
                >
                  <div className="th-container">Trạng Thái</div>
                </th>
                <th
                  class="font-weight-bold align-center"
                  style={{ width: "100px" }}
                >
                  <div className="th-container">{lang["log.action"]}</div>
                </th>
                {/* <th class="font-weight-bold align-center" style={{ width: "350px" }} scope="col">Thao tác</th> */}
              </tr>
              <tr class="input-search-cus">
                <th style={{ borderBottom: "0.5px solid #dee2e6" }}></th>
                <th style={{ borderBottom: "0.5px solid #dee2e6" }}>
                  <input
                    type="search"
                    className="form-control "
                    // value={
                    //   inputValues["CompanyName"]||""
                    // }
                    onChange={(e) =>
                      handleInputChange("CompanyName", e.target.value)
                    }
                    onKeyDown={handleKeyDown}
                  />
                </th>
                <th style={{ borderBottom: "0.5px solid #dee2e6" }}>
                  <input
                    type="search"
                    className="form-control "
                    // value={
                    //   inputValues["TotalKey"] || ""
                    // }
                    onChange={(e) =>
                      handleInputChange("TotalKey", e.target.value)
                    }
                    onKeyDown={handleKeyDown}
                  />
                </th>
                <th style={{ borderBottom: "0.5px solid #dee2e6" }}>
                  <input
                    type="search"
                    className="form-control "
                    // value={
                    //   inputValues["TotalCase"] || ""
                    // }
                    onChange={(e) =>
                      handleInputChange("TotalCase", e.target.value)
                    }
                    onKeyDown={handleKeyDown}
                  />
                </th>
                <th style={{ borderBottom: "0.5px solid #dee2e6" }}>
                  <input
                    type="search"
                    className="form-control "
                    // value={
                    //   inputValues["TotalProcessing"] || ""
                    // }
                    onChange={(e) =>
                      handleInputChange("TotalProcessing", e.target.value)
                    }
                    onKeyDown={handleKeyDown}
                  />
                </th>

                <th
                  class="align-center"
                  onClick={handleSearchClick}
                  style={{
                    minWidth: "100px",
                    borderBottom: "0.5px solid #dee2e6",
                  }}
                >
                  <i
                    class={`fa fa-search size-24 pointer mb-2 ${
                      isActive ? "icon-active" : ""
                    }`}
                    title={lang["search"]}
                  ></i>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>in không ra mực</td>
                <td>in không ra mực...</td>
                <td>Apr 10, 2024</td>
                <td>
                  <label class="text-totalcasefailed">Cancel</label>
                </td>
                <td class="align-center" style={{ width: "100px" }}>
                  {checkDetail && (
                    <i
                      className="fa fa-eye size-24 pointer icon-view"
                      onClick={() => viewpageSample()}
                      title={lang["viewdetail"]}
                    ></i>
                  )}
                </td>
              </tr>
              <tr>
                <td>2</td>
                <td>R20 in không ra mực</td>
                <td>in không ra mực...</td>
                <td>Apr 10, 2024</td>
                <td>
                  <label class="text-totalcasefailed">Cancel</label>
                </td>
                <td class="align-center" style={{ width: "100px" }}>
                  {checkDetail && (
                    <i
                      className="fa fa-eye size-24 pointer icon-view"
                      onClick={() => viewpageSample()}
                      title={lang["viewdetail"]}
                    ></i>
                  )}
                </td>
              </tr>
              <tr>
                <td>3</td>
                <td>R60 in không ra mực</td>
                <td>in không ra mực...</td>
                <td>Apr 10, 2024</td>
                <td>
                  <label class="text-totalcasefailed">Cancel</label>
                </td>
                <td class="align-center" style={{ width: "100px" }}>
                  {checkDetail && (
                    <i
                      className="fa fa-eye size-24 pointer icon-view"
                      onClick={() => viewpageSample()}
                      title={lang["viewdetail"]}
                    ></i>
                  )}
                </td>
              </tr>
              <tr>
                <td>4</td>
                <td>R10 in bị sọc</td>
                <td>in không ra mực...</td>
                <td>Apr 10, 2024</td>
                <td>
                  <label class="text-totalcasefailed">Cancel</label>
                </td>
                <td class="align-center" style={{ width: "100px" }}>
                  {checkDetail && (
                    <i
                      className="fa fa-eye size-24 pointer icon-view"
                      onClick={() => viewpageSample()}
                      title={lang["viewdetail"]}
                    ></i>
                  )}
                </td>
              </tr>
              <tr>
                <td>5</td>
                <td>Rx in không ra mực</td>
                <td>in không ra mực...</td>
                <td>Apr 10, 2024</td>
                <td>
                  <label class="text-totalcasefailed">Cancel</label>
                </td>
                <td class="align-center" style={{ width: "100px" }}>
                  {checkDetail && (
                    <i
                      className="fa fa-eye size-24 pointer icon-view"
                      onClick={() => viewpageSample()}
                      title={lang["viewdetail"]}
                    ></i>
                  )}
                </td>
              </tr>
              <tr>
                <td style={{ height: lastRowFitHeight({}) }}></td>
              </tr>
            </tbody>
          </table>
        </>
      </div>
      <div className="d-flex justify-content-between align-items-center mt-1 footeritem">
        <p>Hiển thị 1-5 của 5 kết quả</p>
        <nav aria-label="Page navigation example">
          <ul className="pagination mb-0">
            {/* Nút đến trang đầu */}
            <li className={`page-item 'disabled' : ''}`}>
              <button className="page-link">&#8810;</button>
            </li>
            <li className={`page-item 'disabled' : ''`}>
              <button className="page-link">&laquo;</button>
            </li>
            <li cclassName={`page-item active`}>
              <button className="page-link active">1</button>
            </li>
            <li className={`page-item `}>
              <button className="page-link">&raquo;</button>
            </li>
            {/* Nút đến trang cuối */}
            <li className={`page-item `}>
              <button className="page-link">&#8811;</button>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
}

export default Active_Helpdesk;
