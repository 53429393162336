import React, { useState, useEffect, useRef, useMemo } from "react";
import copy from "copy-to-clipboard";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { Tooltip, Pagination, Flex } from "antd";
export default (props) => {
  const [isActivated, setIsActivated] = useState(false);
  const { lang, proxy, auth, pages, functions, socket } = useSelector(
    (state) => state
  );
  let navigate = useNavigate();

  const { openTab, renderDateTimeByFormat } = functions;
  const _token = localStorage.getItem("_token");
  const stringifiedUser = localStorage.getItem("user");
  const storedPwdString = localStorage.getItem("password_hash");
  const { project_id, version_id, url } = useParams();
  const _user = JSON.parse(stringifiedUser) || {};
  const [apiDataName, setApiDataName] = useState([]);
  const [inputValues, setInputValues] = useState({});
  const [searchValues, setSearchValues] = useState({});
  const [apiData, setApiData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isActive, setIsActive] = useState(false);
  let CompanyName = localStorage.getItem("CompName");
  //const storedPwdString = localStorage.getItem("password_hash");
  const page = props.page;
  const checkDetail = "/d/ED62F146466648A18B9F2F82928B1A5C";
  const [dataTable_id, setDataTableID] = useState(null);
  const handleSubmit = (e) => {
    e.preventDefault();
    setIsActivated(true);

    // TODO: Thêm mã để xử lý thông tin form và tạo mã kích hoạt
  };
  let totalkey = localStorage.getItem("totalkey");

  useEffect(() => {
    if (page) {
      // const id_str = page.components?.[0]?.api_post.split('/')[2];
      const id_str = "8FD3A675DA4846CA9ABBF936C0373817"; //post
      // console.log("1",id_str)
      fetch(`${proxy()}/apis/api/${id_str}/input_info`, {
        headers: {
          Authorization: _token,
        },
      })
        .then((res) => res.json())
        .then((res) => {
          const { data, success, content } = res;
          //console.log(res);
          if (success) {
            // setDataTables(data.tables)
            setDataTableID(data.tables[0].id);
            // setDataFields(data.body)
            // setLoaded(true)
          }
        });
    }
  }, [page, dataTable_id]);
  const fetchCount = useRef(0);

  //console.log(apiData);
  //console.log(searchValues);

  //console.log(mappedfilter);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const requestBody = {
          checkCustomer: {
            username: CompanyName,
            password: "53e6086284353cb73d4979f08537d950",
          },
        };

        const response = await fetch(
          `${proxy()}/api/8CFE51DB2E0555635560082AE49D1DFD`,
          {
            headers: {
              Authorization: _token,
              "content-type": "application/json",
            },
            method: "POST",
            body: JSON.stringify(requestBody),
          }
        );
        const resp = await response.json();
        const { success, content, data, count, fields } = resp;
        //console.log(data);
        //console.log(dataPrev);
        if (data !== apiData) {
          setApiData(data);
          fetchCount.current++;
          //setDataPrev(newData);
        } else {
          //setDataPrev(newData);
          fetchCount.current = 0;
        }
      } catch {}
      //});
    };

    // Check if the fetch count has reached the limit
    if (fetchCount.current < 3) {
      // Replace 5 with your desired limit
      //console.log(fetchCount.current);
      fetchData();
    }
  }, [apiData]);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  //map dữ liệu từ fetch
  const mappedapiData = apiData?.map((item) => ({
    id: item["1I"], //id
    Type: item["23T"],
    Model: item["19M"], //UUID
    Serial: item["11S"], //Serial
    RequestQuantity: item["2R"],
    UserCreate: item["5C"],
    IPAddress: item["IP"],
    Location: item["Location"],
  }));
  //group lại theo type và model
  const groupedData = mappedapiData.reduce((acc, item) => {
    const key = `${item.Type}-${item.Model}`;
    if (!acc[key]) {
      acc[key] = { Type: item.Type, Model: item.Model, TotalQuantity: 0 };
    }
    acc[key].TotalQuantity += 1; // parseInt(item.RequestQuantity, 10);
    return acc;
  }, {});
  const result = Object.values(groupedData);
  const filteredData = useMemo(() => {
    if (Object.keys(searchValues).length === 0) {
      return result;
    }
    return result.filter((row) =>
      Object.entries(searchValues).every(([key, value]) =>
        row[key]?.toString().toLowerCase().includes(value?.toLowerCase())
      )
    );
  }, [searchValues, result]);
  //Phân trang
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 13;
  const indexOfLast = currentPage * rowsPerPage;
  const indexOfFirst = indexOfLast - rowsPerPage;
  const current = filteredData.slice(indexOfFirst, indexOfLast);
  //console.log(current);
  const mappedcurrent = current?.map((item) => ({
    id: item["id"], //id
    Type: item["Type"],
    Model: item["Model"], //UUID
    TotalQuantity: item["TotalQuantity"],
  }));
  const totalPages = Math.ceil(filteredData.length / rowsPerPage);

  const [isCopied, setIsCopied] = useState(false);
  //
  const handleViewDetail = async (record) => {
    const id_str = "ED62F146466648A18B9F2F82928B1A5C";
    if (id_str) {
      try {
        const response = await fetch(
          `${proxy()}/apis/api/${id_str}/input_info`,
          {
            headers: { Authorization: _token },
          }
        );
        const { success, data } = await response.json();
        if (success) {
          const stringifiedParams = data.body
            .map((param) => {
              const { fomular_alias } = param;
              return record;
            })
            .join("/");
          goToPage(
            `KeyDetailViewer/key/detail-key/${id_str}/${stringifiedParams}`
          );
        }
      } catch (error) {
        console.error(error);
        Swal.fire({
          title: lang["faild"],
          text: lang["not found"],
          icon: "error",
          showConfirmButton: false,
          timer: 2000,
        });
      }
    }
  };
  //
  const goToPage = (label) => {
    navigate(`/` + label);
  };
  const handleCopy = (event) => {
    event.preventDefault();
    copy();

    setIsCopied(true);

    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };
  const handleInputChange = (fomular_alias, value) => {
    setInputValues((prevValues) => ({
      ...prevValues,
      [fomular_alias]: value,
    }));
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === 13 || event.keyCode === 8) {
      handleSearchClick();
    }
  };
  const lastRowFitHeight = () => {
    return "320px";
  };
  //mở trang tạm
  const viewpageSample = (key) => {
    handleViewDetail(key);
  };
  const handleSearchClick = () => {
    setCurrentPage(1);
    setSearchValues(inputValues);

    setIsActive(true);
    setTimeout(() => {
      setIsActive(false);
    }, 300);
  };
  function formatPrinterKey(key) {
    return key.match(/.{1,4}/g).join("-");
  }

  const rawKey = "PRTA123B45PRTA123B45";
  const formattedKey = formatPrinterKey(rawKey);

  const [heightChat, setHeightChat] = useState(0);
  const windowHeightRespon = window.innerHeight;
  const calculateHeight = () => {
    const baseHeight = windowHeightRespon;
    const maxHeightReduction = 96 - 40;
    const heightReduction =
      heightChat > 40 ? Math.min(heightChat - 40, maxHeightReduction) : 0;
    return `${Math.max(baseHeight - heightReduction, 585)}`;

    return `${Math.max(baseHeight - heightReduction, 585)}`;
  };
  return (
    <>
      <div class="col-md-12" style={{ height: calculateHeight() }}>
        <div class="row">
          <label class="text-general-nonebold">Total</label>
          <label class="text-totalcase">{totalkey ? totalkey : 0}</label>
          <label class="text-general-nonebold">Keys Active Created</label>
        </div>
        <div
          class="table-responsive"
          style={{ overflow: "auto", height: "67vh" }}>
          <>
            <table class="table table  ">
              <thead>
                <tr class="color-tr sticky-header">
                  <th
                    class="font-weight-bold "
                    style={{ width: "50px", minWidth: "50px" }}
                    scope="col">
                    {lang["log.no"]}
                  </th>
                  <th class="font-weight-bold align-center">Model</th>
                  <th class="font-weight-bold align-center">Type</th>
                  <th class="font-weight-bold align-center">Keys Quantity</th>
                </tr>
                <tr class="input-search-cus">
                  <th style={{ borderBottom: "0.5px solid #dee2e6" }}></th>
                  <th style={{ borderBottom: "0.5px solid #dee2e6" }}>
                    <input
                      type="search"
                      className="form-control "
                      // value={
                      //   inputValues["CompanyName"]||""
                      // }
                      onChange={(e) =>
                        handleInputChange("Model", e.target.value)
                      }
                      onKeyDown={handleKeyDown}
                    />
                  </th>
                  <th style={{ borderBottom: "0.5px solid #dee2e6" }}>
                    <input
                      type="search"
                      className="form-control "
                      onChange={(e) =>
                        handleInputChange("Type", e.target.value)
                      }
                      onKeyDown={handleKeyDown}
                    />
                  </th>
                  <th style={{ borderBottom: "0.5px solid #dee2e6" }}>
                    <input
                      type="search"
                      className="form-control "
                      onChange={(e) =>
                        handleInputChange("TotalQuantity", e.target.value)
                      }
                      onKeyDown={handleKeyDown}
                    />
                  </th>
                </tr>
              </thead>
              <tbody>
                {loading && current && current.length > 0 ? (
                  <tr>
                    <td
                      class=""
                      colspan={`${result?.length + 2}`}
                      style={{ textAlign: "center" }}>
                      <div>
                        <img
                          width={48}
                          className="mb-1"
                          src="/images/icon/load.gif"
                          alt="Loading..."></img>
                      </div>
                    </td>
                  </tr>
                ) : (
                  <>
                    {current && current.length > 0 ? (
                      mappedcurrent.map((row, index) => {
                        if (row) {
                          return (
                            <>
                              <tr key={row.id}>
                                <td class="align-center">{index + 1}</td>
                                <td>
                                  <span class="table-td-product-pl-6">
                                    {row.Model}
                                  </span>
                                </td>
                                <td>
                                  <span class="table-td-product-pl-6">
                                    {row.Type}
                                  </span>
                                </td>
                                <td>
                                  <span class="table-td-product-pl-6">
                                    {row.TotalQuantity}
                                  </span>
                                </td>
                              </tr>
                            </>
                          );
                        } else {
                          return null;
                        }
                      })
                    ) : (
                      <tr>
                        <td
                          class=""
                          colspan={4}
                          style={{ textAlign: "center" }}>
                          <div>{lang["not found"]}</div>
                        </td>
                      </tr>
                    )}
                  </>
                )}
                {/* <tr>
                <td style={{ height: lastRowFitHeight({}) }}></td>
              </tr> */}
              </tbody>
            </table>
          </>
        </div>
      </div>
      <div class="bottom-div">
        <Flex align="center" gap="small" justify="center">
          <Pagination
            total={result.length}
            showTotal={(total, range) =>
              `${range[0]}-${range[1]} of ${total} items`
            }
            onChange={(value) => {
              paginate(value);
            }}
            defaultPageSize={rowsPerPage}
            defaultCurrent={1}
            showSizeChanger={false}
          />
        </Flex>
      </div>
    </>
  );
};
