import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import {
  Varchar,
  Char,
  Text,
  Int,
  DateInput,
  TimeInput,
  DateTimeInput,
  Decimal,
  Bool,
  DataEmail,
  DataPhone,
  Label,
} from "../../../../inputs";

import TableInputUpdate from "../../../layout_feedback/table/table-input-update";
import TableComponentInputUpdate from "../../../layout_feedback/table/table-component-input-update";
import moment, { updateLocale } from "moment/moment";

export default () => {
  const { lang, proxy, auth, functions } = useSelector((state) => state);
  let langItemCheck = localStorage.getItem("lang");
  const stringifiedUser = localStorage.getItem("user");
  const _user = JSON.parse(stringifiedUser) || {};
  const username = _user.Username;

  const dispatch = useDispatch();
  const { id_str } = useParams();
  const _token = localStorage.getItem("_token");

  const storedPwdString = localStorage.getItem("password_hash");
  const { project_id, version_id, url } = useParams();
  let navigate = useNavigate();

  const [api, setApi] = useState({});
  const [tables, setTables] = useState([]);
  const [keyFields, setKeyFields] = useState([]);
  const [params, setParams] = useState([]);
  const [fields, setFields] = useState([]);
  const [data, setData] = useState({});
  const [relatedTables, setRelatedTables] = useState([]);
  const [initialData, setInitData] = useState({});
  const [loaded, setLoaded] = useState(false);
  const [page, setPage] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [attachMedia, setAttachMedia] = useState([]);
  const [supportQuanlity, setSupportQuanlity] = useState(0);
  //Data
  const [dataCaseDetail, setDataCaseDetail] = useState({});
  const [selectedCaseDetail, setSelectedCaseDetail] = useState("");
  const [showPageDetail, setShowPageDetail] = useState(false);
  //End Data

  const listError = [
    { id: 0, label: "Undefined", value: 1, color: "#808080" }, // Màu xám
    { id: 1, label: "Troubleshooting", value: 2, color: "#007BFF" }, // Màu xanh dương
    { id: 2, label: "Error", value: 3, color: "#FF0000" }, // Màu đỏ
    { id: 3, label: "Question", value: 4, color: "#15bd4c" }, // Màu xanh lá cây nhạt
    { id: 4, label: "Feature", value: 5, color: "#800080" }, // Màu tím
    { id: 5, label: "Project", value: 6, color: "#0f46bb" }, // Màu xanh lam nhạt
  ];
  // Data table
  const [tableData, setTableData] = useState([]); // Nhận từ Cpn con
  const [tableComponentData, setTableComponentData] = useState([]);
  const [tableDataProduct, setTableDataProduct] = useState([]);
  const [tableComponentDataProduct, setTableComponentDataProduct] = useState(
    []
  );
  const filteredTableData = tableData.filter((item) => {
    // Kiểm tra xem có ít nhất một trong các thuộc tính col1, col2, col3, col4, col5 có giá trị không
    return item.col1 || item.col2 || item.col3 || item.col4 || item.col5;
  });
  const filteredTableComponentData = tableComponentData.filter((item) => {
    return item.col || item.col2;
  });
  const mappedComponentArray = filteredTableComponentData.map((item) => ({
    "1CPN": item.col1,
    "1QTT": item.col2,
  }));
  const mappedArray = filteredTableData.map((item) => ({
    "2SN": item.col1,
    "1HV": item.col2,
    "1FV": item.col3,
    "1SV": item.col4,
    "10Q": item.col5,
  }));

  const [rating, setRating] = useState(null);
  const handleRatingClick = (newRating) => {
    if (
      dataCaseDetail.status === "Active" ||
      dataCaseDetail.status === "In Progress" ||
      dataCaseDetail.status === "Resolved"
    ) {
      setRating(newRating);
    }
  };
  const handleDataFromChild_Component = (newData) => {
    setTableComponentData(newData);
  };
  const handleDataFromChild = (newData) => {
    setTableData(newData);
  };

  // Hàm đóng modal
  const handleCloseModal = () => {
    setShowModal(false);
    setAttachMedia([]);
  };
  const fetchDataProduct = async (caseid) => {
    try {
      const requestBodyProduct = {
        checkCustomer: {
          username,
          password: storedPwdString,
        },
        "3CI": caseid,
      };
      // Trước khi gọi API, đặt giá trị của tableDataProduct thành một mảng rỗng
      setTableDataProduct([]);

      const response = await fetch(
        `${proxy()}/api/F256DE8ACBC449F3A4B5E2056FF8F18E`,
        {
          headers: {
            Authorization: _token,
            "content-type": "application/json",
          },
          method: "POST",
          body: JSON.stringify(requestBodyProduct),
        }
      );
      const resp = await response.json();
      setTableDataProduct(resp.Details);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  //fcth datee component
  const fetchComponentDataProduct = async (caseid) => {
    try {
      const requestBodyProduct = {
        checkCustomer: {
          username,
          password: storedPwdString,
        },
        "1CID_C": caseid,
      };
      // Trước khi gọi API, đặt giá trị của tableDataProduct thành một mảng rỗng
      setTableComponentDataProduct([]);

      const response = await fetch(
        `${proxy()}/api/342EE4FC973EDD946B4B47636178562F`,
        {
          headers: {
            Authorization: _token,
            "content-type": "application/json",
          },
          method: "POST",
          body: JSON.stringify(requestBodyProduct),
        }
      );
      const resp = await response.json();
      setTableComponentDataProduct(resp.Details);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handlePageDetail = (caseid) => {
    setDataCaseDetail({});
    fetchDataProduct(caseid);
    fetchComponentDataProduct(caseid);
    setTableComponentDataProduct([]);
    setTableDataProduct([]);
    setSelectedCaseDetail(caseid);
    //localStorage.setItem("selectedCaseDetail", caseid.id);
    //console.log(selectedCaseDetail);
    setShowPageDetail(true);
    //callApiMessage();

    //callApiMessageMedia();
    callApiCaseDetail(caseid);
  };

  useEffect(() => {
    const storedSelectedCaseDetail = localStorage.getItem("selectedCaseDetail");
    if (storedSelectedCaseDetail) {
      handlePageDetail(storedSelectedCaseDetail);
    }
  }, [selectedCaseDetail]);

  // const windowHeightRespon = window.innerHeight;
  // const calculateHeight = () => {
  //   let baseHeight = windowHeightRespon;
  //   // console.log("Chieu cao man hinh:", baseHeight)
  //   const imageAdjustment = selectedImagesSent.length > 0 ? 70 : 0; // Điều chỉnh nếu có hình ảnh
  //   const maxHeightReduction = 96 - 40; // Giới hạn giảm chiều cao tối đa

  //   if (heightChat > 40) {
  //     const heightReduction = Math.min(heightChat - 40, maxHeightReduction);
  //     baseHeight -= heightReduction;
  //   }
  //   // Trừ đi imageAdjustment trước khi áp dụng giới hạn tối thiểu 588
  //   // Đảm bảo baseHeight không thấp hơn 588
  //   baseHeight = Math.max(baseHeight, 585);
  //   baseHeight -= imageAdjustment;
  //   return `${baseHeight}`;
  // };
  //console.log(selectedCaseDetail);
  const callApiCaseDetail = (caseID) => {
    if (caseID != "") {
      //console.log(caseID);
      const requestBody = {
        checkUser: {
          username: "DIPES-TEAM",
          password: "a01555daf781180515dec9895c40f882",
        },
        checkCustomer: {
          username,
          password: storedPwdString,
        },
        "1CI": caseID,
      };
      //console.log(requestBody);
      fetch(`${proxy()}/api/1281201C63B6454BB5629E2DFE1186BD`, {
        headers: {
          Authorization: _token,
          "content-type": "application/json",
        },
        method: "POST",
        body: JSON.stringify(requestBody),
      })
        .then((res) => res.json())
        .then((resp) => {
          const { Success, data, activated, status, content, Case, fields } =
            resp;
          //console.log(429, resp);
          if (!Case || !fields) {
            return;
          }
          const fieldMappings = resp.fields.reduce((acc, field) => {
            acc[field.fomular_alias] = field.field_name;
            return acc;
          }, {});

          const mappedCase =
            Object.keys(resp.Case).length > 0 &&
            Object.keys(resp.Case).reduce((newCase, key) => {
              const newKey = fieldMappings[key] || key;
              newCase[newKey] = resp.Case[key];
              return newCase;
            }, {});

          // console.log(427, mappedCase);
          const caseDetail = {
            id: mappedCase["CASE ID"],
            title: mappedCase["CASE TITLE"],
            date: mappedCase["CREATED DATE"],
            casetype: mappedCase["CASE TYPE"],
            issue: mappedCase["ISSUE DESCRIPTION"],
            issuepart: mappedCase["1IP"],
            customer: mappedCase["CUSTOMER"],
            status: mappedCase["STATUS"],
            imgcase: mappedCase["CASE IMAGE"],
            solution: mappedCase["SOLUTION DESCRIPTION"],
            attachMedia: mappedCase["1CA"],
            supportquanlity: mappedCase["SUPPORT QUALITY"],
            supportdescription: mappedCase["SUPPORT QUALITY DESCRIPTION"],
            lastedsupport: mappedCase["APPRICATOR"],
            possibleFeature: mappedCase["POSSIBLE FEATURE"],
            caseSuggest: mappedCase["CASE SUGGEST"],
            warranty: mappedCase["WARRANTY CLAIM"],
            dateoccur: mappedCase["1DO"],
            caseno: mappedCase["1CC"],
            troublecategory: mappedCase["1TC"],
            angentcasestatus: mappedCase["1ACS"],
            subagent: mappedCase["1PN"],
            caseby: mappedCase["1CB"],
            issueclassifi: mappedCase["1IC"],
            enduser: mappedCase["1EU"],
            factoryitem: mappedCase["1FI"],
            newprinterlotboard: mappedCase["1NPLB"],
            newreplaceditem: mappedCase["1NRI"],
            origionallotboard: mappedCase["1OL"],
            returnitem: mappedCase["1RI"],
            shippinginformation: mappedCase["1SI"],
          };
          //console.log(442, caseDetail);
          setDataCaseDetail(caseDetail);
        });
    }
  };

  const initialActiveTab = localStorage.getItem("activeTab") || "general";
  const [activeTab, setActiveTab] = useState(initialActiveTab);

  // Hàm xử lý khi tab được chọn
  const handleTabClick = (tab, caseid) => {
    setActiveTab(tab);
    fetchDataProduct(caseid);
    fetchComponentDataProduct(caseid);
  };

  // Sử dụng useEffect để lưu trạng thái activeTab vào localStorage khi có sự thay đổi
  useEffect(() => {
    localStorage.setItem("activeTab", activeTab);
  }, [activeTab]);

  const getInitialState = (key, defaultValue) => {
    const storedValue = localStorage.getItem(key);
    return storedValue !== null ? JSON.parse(storedValue) : defaultValue;
  };
  const [isOpenGeneral, setIsOpenGeneral] = useState(
    getInitialState("isOpenGeneral", false)
  );
  const [isOpenProduction, setIsOpenProduction] = useState(
    getInitialState("isOpenProduction", false)
  );
  const [isOpenDiscussion, setIsOpenDiscussion] = useState(
    getInitialState("isOpenDiscussion", false)
  );
  const [isOpenSupport, setIsOpenSupport] = useState(
    getInitialState("isOpenSupport", false)
  );
  //Check India show colapse
  function autoHideNoteAsIndia() {
    return "d-flex";
  }
  function checkShowColapse() {
    return "accordion-collapse collapse container-case show";
  }
  // Lưu trạng thái vào localStorage mỗi khi nó thay đổi
  useEffect(() => {
    localStorage.setItem("isOpenGeneral", JSON.stringify(isOpenGeneral));
  }, [isOpenGeneral]);

  useEffect(() => {
    localStorage.setItem("isOpenProduction", JSON.stringify(isOpenProduction));
  }, [isOpenProduction]);

  useEffect(() => {
    localStorage.setItem("isOpenDiscussion", JSON.stringify(isOpenDiscussion));
  }, [isOpenDiscussion]);

  useEffect(() => {
    localStorage.setItem("isOpenSupport", JSON.stringify(isOpenSupport));
  }, [isOpenSupport]);

  const toggleCollapseGeneral = () => {
    setIsOpenGeneral(!isOpenGeneral);
  };
  const toggleCollapseProduction = () => {
    setIsOpenProduction(!isOpenProduction);
  };
  const toggleCollapseDiscussion = () => {
    setIsOpenDiscussion(!isOpenDiscussion);
  };
  const toggleCollapseSupport = () => {
    setIsOpenSupport(!isOpenSupport);
  };
  // // Gọi dữ liệu tin nhắn
  // const callApiMessage = () => {
  //   const requestBody = {
  //     checkCustomer: {
  //       username,
  //       password: storedPwdString,
  //     },
  //     "4CI": selectedCaseDetail,
  //   };
  //   return fetch(`${proxy()}/api/35DAEDC33BF24327A03373D4D66B1D2B`, {
  //     headers: {
  //       Authorization: _token,
  //       "content-type": "application/json",
  //     },
  //     method: "POST",
  //     body: JSON.stringify(requestBody),
  //   })
  //     .then((res) => res.json())
  //     .then((resp) => {
  //       const { success, data, activated, status, content } = resp;
  //       // console.log("datamessage", resp)

  //       if (!functions.arraysAreEqual(resp.Messages, dataMessagePrev)) {
  //         setDataMessage(resp.Messages);
  //         setDataMessagePrev(resp.Messages); // Lưu bản sao mới
  //       }
  //       // console.log(functions.arraysAreEqual(resp.Messages, dataMessagePrev))
  //       return resp;
  //     });
  // };
  // Gọi dữ liệu Media
  // const callApiMessageMedia = () => {
  //   const requestBody = {
  //     checkCustomer: {
  //       username,
  //       password: storedPwdString,
  //     },
  //     "5CI": selectedCaseDetail,
  //   };
  //   return fetch(`${proxy()}/api/C859083907874976BA90AAA4D14D8E61`, {
  //     headers: {
  //       Authorization: _token,
  //       "content-type": "application/json",
  //     },
  //     method: "POST",
  //     body: JSON.stringify(requestBody),
  //   })
  //     .then((res) => res.json())
  //     .then((resp) => {
  //       const { success, data, activated, status, content } = resp;
  //       // console.log("data media", resp)
  //       if (!functions.arraysAreEqual(resp.Media, dataMessageMediaPrev)) {
  //         setDataMessageMedia(resp.Media);
  //         setDataMessageMediaPrev(resp.Media); // Lưu bản sao mới
  //       }
  //       return resp;
  //     });
  // };
  const handleDownload = (url) => {
    window.open(url, "_blank");
  };
  const goToHomePage = () => {
    navigate(`/CaseDetail`);
  };
  // Lấy dữ liệu preview media
  const [dataPreviewMedia, setDataPreviewMedia] = useState();
  const openModalPreview = (media) => {
    setDataPreviewMedia(media);
  };

  // Lấy dữ liệu preview file
  const [dataPreviewFile, setDataPreviewFile] = useState();
  // console.log(dataPreviewFile)
  const openModalPreviewFile = (media) => {
    setDataPreviewFile(media);
  };
  const [currentTimestamp, setCurrentTimestamp] = useState(new Date());

  const getElapsedTime = (notifyAt) => {
    if (!notifyAt) {
      return "";
    }
    try {
      // Trích xuất phần số từ chuỗi ngày
      const match = notifyAt.match(/\/Date\((\d+)\+\d+\)\//);
      if (!match) {
        throw new Error("Invalid date format");
      }

      const notifyTimestamp = new Date(parseInt(match[1], 10));
      const elapsedMilliseconds = currentTimestamp - notifyTimestamp;
      const elapsedSeconds = Math.floor(elapsedMilliseconds / 1000);
      const elapsedMinutes = Math.floor(elapsedSeconds / 60);
      const elapsedHours = Math.floor(elapsedMinutes / 60);
      const elapsedDays = Math.floor(elapsedHours / 24);
      const elapsedMonths = Math.floor(elapsedDays / 30);
      const elapsedYears = Math.floor(elapsedMonths / 12);

      if (elapsedYears > 0) {
        return `${elapsedYears} ${lang["years ago"]}`;
      } else if (elapsedMonths > 0) {
        return `${elapsedMonths} ${lang["months ago"]}`;
      } else if (elapsedDays > 0) {
        return `${elapsedDays} ${lang["days ago"]}`;
      } else if (elapsedHours > 0) {
        return `${elapsedHours} ${lang["hours ago"]}`;
      } else if (elapsedMinutes > 0) {
        return `${elapsedMinutes} ${lang["mins ago"]}`;
      } else if (elapsedMilliseconds > 0) {
        return `${elapsedSeconds} ${lang["secs ago"]}`;
      } else {
        return lang["just now"];
      }
    } catch (error) {
      console.error(error);
      return "";
    }
  };
  const result = { title: "Case Details" };

  return (
    <div class="midde_cont">
      <div class="container-fluid">
        <div class="row column_title">
          <div class="col-md-12">
            <div class="page_title">
              <h4 class="ml-1">{lang["ad dashboard"]}</h4>
            </div>
          </div>
        </div>
        {/* List table */}
        <div class="row">
          <div class="col-md-12">
            <div class="white_shd full">
              <div class="full graph_head">
                <div class="row">
                  <div class="heading1 margin_0 ">
                    {/* <h5> <a onClick={() => navigate(-1)}><i class="fa fa-chevron-circle-left mr-3"></i></a>{page?.components?.[0]?.component_name}</h5> */}
                    <h5>
                      <label class="pointer" onClick={() => goToHomePage()}>
                        <label class="pointer">
                          <a tittle={lang["back"]}>
                            <i class=" fa fa-chevron-circle-left mr-1 mt-3 mb-0 nav-item nav-link"></i>
                          </a>
                        </label>
                        <label
                          class="text-general-nonebold"
                          style={{ paddingRight: "10px" }}>
                          {result?.title}
                        </label>
                      </label>
                      <i class="fa fa-chevron-right"></i> {lang["viewdetail"]}
                    </h5>
                  </div>
                  {/* <div class="ml-auto">
                                <i class="fa fa-newspaper-o icon-ui"></i>
                            </div> */}
                </div>
              </div>
              <div class="table_section padding_infor_info">
                {/* Detail Case */}

                <div class="full graph_head_cus min-h-58">
                  <div class="heading1 margin_0 case-detail">
                    <div class="d-flex ">
                      <h4
                        class="ellipsis-header-case mr-2"
                        title={dataCaseDetail.caseno}>
                        [CaseNo.{dataCaseDetail.caseno}]
                      </h4>
                      <h4
                        class="ellipsis-header-case mr-2"
                        title={dataCaseDetail.title}>
                        {dataCaseDetail.title}
                      </h4>
                      <span
                        class="ml-auto font-weight-bold-black"
                        style={{
                          color:
                            (
                              listError.find(
                                (s) => s.label === dataCaseDetail.casetype
                              ) || {}
                            ).color || "#000000",
                        }}>
                        {(
                          listError.find(
                            (s) => s.label === dataCaseDetail.casetype
                          ) || {}
                        ).label || ""}
                      </span>
                    </div>
                    <div class="d-flex ">
                      <p class="italic" style={{ marginBottom: 0 }}>
                        {lang["Posted on"]}{" "}
                        {dataCaseDetail && langItemCheck === "Vi"
                          ? functions.translateDateToVietnamese(
                              functions.formatDateCase(dataCaseDetail.date)
                            )
                          : functions.formatDateCase(dataCaseDetail.date)}{" "}
                        ({getElapsedTime(dataCaseDetail.date)}).{" "}
                        <b class="status_label">{dataCaseDetail.status}</b>
                      </p>
                      {/* <div class="ml-auto">
                                                    {dataCaseDetail.supportquanlity !== undefined ?
                                                        <img width={32} src={`/images/icon/${qualityToImage[dataCaseDetail.supportquanlity]}`} alt="ex" />
                                                        :
                                                        <div style={{ height: "32px" }}></div>
                                                    }
                                                </div> */}
                    </div>
                    <div class={autoHideNoteAsIndia()}>
                      <div class="col-md-8">
                        <p>
                          {dataCaseDetail.subagent
                            ? dataCaseDetail.subagent
                            : lang["No data avasilable"]}
                        </p>
                      </div>
                      <div class="col-md-4">
                        <p>
                          {lang["case by"]}{" "}
                          <b>
                            {dataCaseDetail.caseby
                              ? dataCaseDetail.caseby
                              : lang["No data avasilable"]}
                          </b>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="table_section padding_infor_info_case_detail">
                  <div>
                    <div className="custom-tab-container">
                      <div
                        className={`custom-tab ${
                          activeTab === "general" ? "custom-tab-active" : ""
                        }`}
                        onClick={() =>
                          handleTabClick("general", dataCaseDetail)
                        }>
                        {lang["general"]}
                      </div>
                      {/* <div className={`custom-tab ${activeTab === 'products' ? 'custom-tab-active' : ''}`} onClick={() => handleTabClick('products')}>
                                                    Products
                                                </div> */}
                      <div
                        className={`custom-tab ${
                          activeTab === "discussion" ? "custom-tab-active" : ""
                        }`}
                        onClick={() =>
                          handleTabClick("discussion", dataCaseDetail)
                        }>
                        {lang["DISCUSSION"]}
                      </div>
                      <div
                        className={`custom-tab ${
                          activeTab === "support" ? "custom-tab-active" : ""
                        }`}
                        onClick={() =>
                          handleTabClick("support", dataCaseDetail)
                        }>
                        {lang["SUPPORT QUALITY"]}
                      </div>
                    </div>
                    <div>
                      {activeTab === "general" && (
                        <div class="container-general">
                          <div class="card-block">
                            <div class="col-md-12">
                              <div
                                class="info-case"
                                style={
                                  {
                                    // height: calculateHeight() - 270 + 3,
                                  }
                                }>
                                <div class="row">
                                  <div class="col-md-8">
                                    <h5 class="mt-2 mb-1">
                                      {lang["ISSUE DESCRIPTION"]}
                                    </h5>
                                    {/* <textarea
                                                                                readOnly
                                                                                class=" form-control"
                                                                                style={{ resize: 'none' }}
                                                                                value={dataCaseDetail.issue}
                                                                                spellCheck="false"
                                                                            ></textarea> */}

                                    {dataCaseDetail.issue &&
                                      dataCaseDetail.issue
                                        .split("\n")
                                        .map((s) => (
                                          <span style={{ display: "block" }}>
                                            {s}
                                          </span>
                                        ))}
                                  </div>
                                </div>
                                <div class="row">
                                  {/**Collapse Advance Info */}
                                  <div
                                    class="accordion accordion-flush"
                                    id="accordionFlushE">
                                    <div class="accordion-item">
                                      <button
                                        class="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseOne"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseOne">
                                        <h5 class="mb-2">
                                          {lang["advance info"]}
                                        </h5>
                                      </button>

                                      <div
                                        id="flush-collapseOne"
                                        class={checkShowColapse()}
                                        aria-labelledby="flush-headingOne"
                                        data-bs-parent="#accordionFlushE">
                                        {/**Collapse content */}

                                        <div class="row">
                                          {/**Trouble Category */}
                                          <div class="col-md-4">
                                            <h5 class="mt-2 mb-1">
                                              {lang["trouble category"]}
                                            </h5>
                                            {dataCaseDetail.troublecategory
                                              ? dataCaseDetail.troublecategory
                                              : lang["No data avasilable"]}
                                          </div>
                                          {/**Date occur */}
                                          <div class="col-md-4">
                                            <h5 class="mt-2 mb-1">
                                              {lang["date occur"]}
                                            </h5>
                                            {moment(
                                              dataCaseDetail.dateoccur
                                            ).format("MM/DD/YYYY")}
                                          </div>
                                          <div class="col-md-4">
                                            <h5 class="mt-2 mb-1">
                                              {lang["case status"]}
                                            </h5>
                                            {dataCaseDetail.angentcasestatus
                                              ? dataCaseDetail.angentcasestatus
                                              : lang["No data avasilable"]}
                                          </div>
                                        </div>
                                        <div class="row field-case">
                                          <div className="col-md-4">
                                            <h5 class="mt-2 mb-1">
                                              {lang["issue part"]}
                                            </h5>
                                            {dataCaseDetail.issuepart
                                              ? dataCaseDetail.issuepart
                                              : lang["No data avasilable"]}
                                          </div>
                                          <div className="col-md-4">
                                            <h5 class="mt-2 mb-1">
                                              {lang["issue classifi"]}
                                            </h5>
                                            {dataCaseDetail.issueclassifi
                                              ? dataCaseDetail.issueclassifi
                                              : lang["No data avasilable"]}
                                          </div>
                                          <div className="col-md-4">
                                            <h5 class="mt-2 mb-1">
                                              {lang["end user"]}
                                            </h5>
                                            {dataCaseDetail.enduser
                                              ? dataCaseDetail.enduser
                                              : lang["No data avasilable"]}
                                          </div>
                                        </div>
                                        {/**Cụm thông tin */}
                                        <div class="row field-case">
                                          <div className="col-md-4">
                                            <h5 class="mt-2 mb-1">
                                              {lang["factory info"]}
                                            </h5>
                                            {(dataCaseDetail.factoryitem
                                              ? dataCaseDetail.factoryitem
                                              : lang["No data avasilable"]
                                            )
                                              .split("\n")
                                              .map((s) => (
                                                <span
                                                  style={{ display: "block" }}>
                                                  {s}
                                                </span>
                                              ))}
                                          </div>
                                          <div className="col-md-4">
                                            <h5 class="mt-2 mb-1">
                                              {lang["new printer lot"]}
                                            </h5>
                                            {(dataCaseDetail.newprinterlotboard
                                              ? dataCaseDetail.newprinterlotboard
                                              : lang["No data avasilable"]
                                            )
                                              .split("\n")
                                              .map((s) => (
                                                <span
                                                  style={{ display: "block" }}>
                                                  {s}
                                                </span>
                                              ))}
                                          </div>
                                          <div className="col-md-4">
                                            <h5 class="mt-2 mb-1">
                                              {lang["new replaced item"]}
                                            </h5>
                                            {(dataCaseDetail.newreplaceditem
                                              ? dataCaseDetail.newreplaceditem
                                              : lang["No data avasilable"]
                                            )
                                              .split("\n")
                                              .map((s) => (
                                                <span
                                                  style={{ display: "block" }}>
                                                  {s}
                                                </span>
                                              ))}
                                          </div>
                                        </div>
                                        <div class="row field-case">
                                          <div className="col-md-4">
                                            <h5 class="mt-2 mb-1">
                                              {lang["origional lot"]}
                                            </h5>
                                            {(dataCaseDetail.origionallotboard
                                              ? dataCaseDetail.origionallotboard
                                              : lang["No data avasilable"]
                                            )
                                              .split("\n")
                                              .map((s) => (
                                                <span
                                                  style={{ display: "block" }}>
                                                  {s}
                                                </span>
                                              ))}
                                          </div>
                                          <div className="col-md-4">
                                            <h5 class="mt-2 mb-1">
                                              {lang["returned item"]}
                                            </h5>
                                            {(dataCaseDetail.returnitem
                                              ? dataCaseDetail.returnitem
                                              : lang["No data avasilable"]
                                            )
                                              .split("\n")
                                              .map((s) => (
                                                <span
                                                  style={{ display: "block" }}>
                                                  {s}
                                                </span>
                                              ))}
                                          </div>
                                          <div className="col-md-4">
                                            <h5 class="mt-2 mb-1">
                                              {lang["shipping info"]}
                                            </h5>
                                            {(dataCaseDetail.shippinginformation
                                              ? dataCaseDetail.shippinginformation
                                              : lang["No data avasilable"]
                                            )
                                              .split("\n")
                                              .map((s) => (
                                                <span
                                                  style={{ display: "block" }}>
                                                  {s}
                                                </span>
                                              ))}
                                          </div>
                                        </div>

                                        <TableComponentInputUpdate
                                          onDataUpdate={
                                            handleDataFromChild_Component
                                          }
                                          fetchDataProduct={
                                            fetchComponentDataProduct
                                          }
                                          dataDetail={dataCaseDetail}
                                          data={tableComponentDataProduct}
                                          stateAdd={false}
                                          caseId={dataCaseDetail.id}
                                          stateUpdate={true}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div class="row field-case">
                                  <div className="col-md-4">
                                    <h5 className="mt-2 mb-2">
                                      {lang["case image"]}
                                    </h5>
                                    <div className="upload-container-case">
                                      {dataCaseDetail.imgcase !== "" ? (
                                        <img
                                          class=""
                                          style={{
                                            maxWidth: "calc(100% - 40px)",
                                            maxHeight: "calc(100% - 10px)",
                                            objectFit: "contain",
                                            borderRadius: "8px",
                                            cursor: "pointer",
                                          }}
                                          src={
                                            dataCaseDetail.imgcase +
                                            "?ver=" +
                                            new Date().getTime()
                                          }
                                          onClick={() =>
                                            openModalPreview({
                                              type: "imageDetail",
                                              url: dataCaseDetail.imgcase,
                                            })
                                          }
                                          data-toggle="modal"
                                          data-target="#previewMedia"
                                          title={lang["Click to preview"]}
                                        />
                                      ) : (
                                        <span>{lang["no image case"]}</span>
                                      )}
                                    </div>
                                  </div>
                                  {/* <div class="col-md-8">
                                                                        <div className="upload-container-case">
                                                                            <img class="" src="/images/helpdesk/r10.png" />
                                                                        </div>
                                                                    </div> */}
                                  <div class="col-md-8">
                                    <h5 className="mt-2 mb-2">
                                      {lang["attachment"]}
                                    </h5>
                                    {dataCaseDetail?.attachMedia?.length > 0 ? (
                                      <>
                                        <div className="upload-container-case-add">
                                          <div className="selected-images-container-add">
                                            {dataCaseDetail?.attachMedia?.map(
                                              (media, index) => (
                                                <div
                                                  key={index}
                                                  className="selected-image-wrapper-add">
                                                  {functions.isImageFormat(
                                                    media["6U"]
                                                  ) && (
                                                    <img
                                                      src={media["6U"]}
                                                      alt={`Selected ${index}`}
                                                      className="selected-image-add pointer"
                                                      data-toggle="modal"
                                                      data-target="#previewMedia"
                                                      onClick={() =>
                                                        openModalPreview({
                                                          type: "attachImageDetail",
                                                          url: media["6U"],
                                                        })
                                                      }
                                                      title={
                                                        lang["Click to preview"]
                                                      }
                                                    />
                                                  )}
                                                  {functions.isVideoFormat(
                                                    media["6U"]
                                                  ) && (
                                                    <div>
                                                      <video
                                                        autoplay
                                                        controls={false}
                                                        src={media["6U"]}
                                                        className="selected-image-add pointer"
                                                        data-toggle="modal"
                                                        data-target="#previewMedia"
                                                        onClick={() =>
                                                          openModalPreview({
                                                            type: "attachVideoDetail",
                                                            url: media["6U"],
                                                          })
                                                        }
                                                        title={
                                                          lang[
                                                            "Click to preview"
                                                          ]
                                                        }></video>
                                                      <div class="video-duration">
                                                        Video
                                                      </div>
                                                    </div>
                                                  )}
                                                  {functions.isPdfFormat(
                                                    media["6U"]
                                                  ) && (
                                                    <img
                                                      src={
                                                        "/images/icon/pdf.svg"
                                                      }
                                                      alt={`Selected ${index}`}
                                                      className="selected-image-add pointer"
                                                      title={media["1FN"]}
                                                      // data-toggle="modal" data-target="#previewFile"
                                                      onClick={() =>
                                                        handleDownload(
                                                          media["6U"]
                                                        )
                                                      }
                                                    />
                                                  )}
                                                  {functions.isExcelFormat(
                                                    media["6U"]
                                                  ) && (
                                                    <img
                                                      src={
                                                        "/images/icon/excel.svg"
                                                      }
                                                      alt={`Selected ${index}`}
                                                      className="selected-image-add pointer"
                                                      title={media["1FN"]}
                                                      onClick={() =>
                                                        handleDownload(
                                                          media["6U"]
                                                        )
                                                      }
                                                    />
                                                  )}
                                                  {functions.isZipFormat(
                                                    media["6U"]
                                                  ) && (
                                                    <img
                                                      src={
                                                        "/images/icon/zip.png"
                                                      }
                                                      alt={`Selected ${index}`}
                                                      className="selected-image-add pointer"
                                                      title={media["1FN"]}
                                                      onClick={() =>
                                                        handleDownload(
                                                          media["6U"]
                                                        )
                                                      }
                                                    />
                                                  )}
                                                </div>
                                              )
                                            )}
                                          </div>
                                        </div>
                                      </>
                                    ) : (
                                      <div className="container-no-attachment">
                                        <span className="span-no-attachment">
                                          {lang["No attachment"]}
                                        </span>
                                      </div>
                                    )}
                                  </div>
                                </div>

                                <TableInputUpdate
                                  onDataUpdate={handleDataFromChild}
                                  fetchDataProduct={fetchDataProduct}
                                  dataDetail={dataCaseDetail}
                                  data={tableDataProduct}
                                  stateAdd={false}
                                  caseId={dataCaseDetail.id}
                                  stateUpdate={true}
                                />

                                <div class="title-suggest mb-1">
                                  {lang["SUGGESTED SOLUTION"]}
                                </div>
                                <span class="content-suggest">
                                  {/* {dataCaseDetail.solution} */}

                                  {dataCaseDetail.solution === "" &&
                                    lang["No data avasilable"]}
                                  {dataCaseDetail.solution &&
                                    dataCaseDetail.solution
                                      .split("\n")
                                      .map((s) => (
                                        <span style={{ display: "block" }}>
                                          {s}
                                        </span>
                                      ))}
                                </span>
                                <div class="row mt-1">
                                  <div className="col-sm-12">
                                    <h5 className="mt-3">
                                      {" "}
                                      {lang["Warranty Claim"]}
                                    </h5>
                                    {/* W23-0000736 */}
                                    {dataCaseDetail.warranty !== ""
                                      ? dataCaseDetail.warranty
                                      : lang["No data avasilable"]}
                                  </div>
                                  <div className="col-sm-12">
                                    <h5 className="mt-3">
                                      {lang["Case Suggest"]}
                                    </h5>
                                    {dataCaseDetail.caseSuggest !== ""
                                      ? dataCaseDetail.warrcaseSuggestanty
                                      : lang["No data avasilable"]}
                                  </div>
                                  <div className="col-sm-12">
                                    <h5 className="mt-3">
                                      {lang["Possible Feature"]}
                                    </h5>
                                    {dataCaseDetail.possibleFeature !== ""
                                      ? dataCaseDetail.possibleFeature
                                      : lang["No data avasilable"]}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
