import { useState, useEffect, useRef, React } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Table from "./tableCaseProcessing/table_CaseProcessing";

export default () => {
  const { lang, proxy, auth } = useSelector((state) => state);
  let navigate = useNavigate();
  let CompanyName = localStorage.getItem("CompName");
  let baseHeight = localStorage.getItem("baseHeight");
  //
  const goToPage = (label) => {
    navigate(`/` + label);
  };
  return (
    <div class="container-case-main">
      <div class="container-fluid">
        <div class="row column_title">
          <div class="col-md-12">
            <div class="page_title">
              <h4>{lang["ad dashboard"]}</h4>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="white_shd full">
              <div class="full graph_head">
                <div class="row">
                  <div class="heading1 margin_0">
                    <h5>
                      <label
                        class="pointer"
                        onClick={() => goToPage("Dashboard")}
                      >
                        <label class="pointer">
                          <a title={lang["back"]}>
                            <i class=" fa fa-chevron-circle-left mr-1 nav-item nav-link"></i>
                          </a>
                        </label>

                        {lang["case processing"]}
                      </label>
                    </h5>
                  </div>
                </div>
                <div class="row">
                  <div class="table_section padding_infor_info_layout_chart ">
                    {lang["customer name"] + ": "}

                    <label class="text-general">{CompanyName}</label>
                  </div>
                </div>
                <div class="row">
                  <div
                    class="table_section padding_infor_info_layout_chart "
                    style={{ minHeight: baseHeight - 60 + "px" }}
                  >
                    <Table />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
