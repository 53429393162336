import { useEffect, React, useState, useCallback, useRef } from "react";
import { batch, useSelector } from "react-redux";
import ReactECharts from "echarts-for-react";
import {
  PieChart,
  Pie,
  Cell,
  Legend,
  Tooltip,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  LineChart,
  Line,
  ResponsiveContainer,
  AreaChart,
  Area,
  ComposedChart,
  ScatterChart,
  Scatter,
} from "recharts";
import GlobalVariable from "../../../../Controllers/Global.Variable";
import { da } from "date-fns/locale";
export default (props) => {
  const { functions, lang, proxy } = useSelector((state) => state);
  const stringifiedUser = localStorage.getItem("user");
  const _user = JSON.parse(stringifiedUser) || {};
  const _token = localStorage.getItem("_token");
  const username =
    _user.Username === "nhan.to" ? "Mylan Digital Solution" : _user.Username;
  const storedPwdString = localStorage.getItem("password_hash");
  const data = props.data;
  const [currentMonth, setSurrentMonth] = useState(new Date().getMonth() + 1);
  const LABELS = ["Resolved", "In Progress", "Cancel", "Active"];
  const COLORS = ["#4988ef", "#72c05d", "#ff7170"];
  const months = [
    lang["january"],
    lang["february"],
    lang["march"],
    lang["april"],
    lang["may"],
    lang["june"],
    lang["july"],
    lang["august"],
    lang["september"],
    lang["october"],
    lang["november"],
    lang["december"],
  ];
  //console.log(props);
  let selectedYear = localStorage.getItem("SeletedYear")
    ? localStorage.getItem("SeletedYear")
    : "2024";

  const [barData, setBarData] = useState([]);

  //console.log(data);
  function groupData(data) {
    const result = {};

    data.forEach((item) => {
      const { name, caseactive, casedone, caseprocessing, casecancel } = item;
      if (!result[name]) {
        result[name] = {
          name,
          caseactive: 0,
          casedone: 0,
          caseprocessing: 0,
          casecancel: 0,
        };
      }
      if (caseactive !== undefined) result[name].caseactive = caseactive;
      if (casedone !== undefined) result[name].casedone = casedone;
      if (caseprocessing !== undefined)
        result[name].caseprocessing = caseprocessing;
      if (casecancel !== undefined) result[name].casecancel = casecancel;
    });

    return Object.values(result);
  }
  useEffect(() => {
    const filteredData = [];

    for (const key in data) {
      const [month, year, type] = key.split(" ");
      const monthYear = `${month} ${year}`;
      let entry = { name: monthYear };
      if (type == "CaseActive") {
        entry.caseactive = data[key];
      }
      if (type == "CaseDone") {
        entry.casedone = data[key];
      }
      if (type == "CaseProcessing") {
        entry.caseprocessing = data[key];
      }
      if (type == "CaseCancel") {
        entry.casecancel = data[key];
      }
      //filteredData[monthYear][type] = data[key];
      //console.log(entry)
      filteredData.push(entry);
    }
    //console.log(filteredData)
    setBarData(groupData(filteredData));
  }, [data]);
  //console.log("case", barData);
  useEffect(() => {
    let yearControllerTotal = 0;
    let yearPrintheadTotal = 0;
    let yearPrinterTotal = 0;
    let monthControllerTotal = 0;
    let monthPrintheadTotal = 0;
    let monthPrinterTotal = 0;
    //

    barData.forEach((item) => {
      //console.log(2,item)
      // Đảm bảo rằng các giá trị là hợp lệ, nếu không sẽ được đặt thành 0
      const casedoneValue = Number.isFinite(item.casedone) ? item.casedone : 0;
      const caseprocessingValue = Number.isFinite(item.caseprocessing)
        ? item.caseprocessing
        : 0;
      const casecancelValue = Number.isFinite(item.casecancel)
        ? item.casecancel
        : 0;

      yearControllerTotal += casedoneValue;
      yearPrintheadTotal += caseprocessingValue;
      yearPrinterTotal += casecancelValue;

      const monthAndYear = item.name.split(" ");
      const itemMonth = monthAndYear[0];
      const itemYear = monthAndYear[1];

      const monthAbbreviation = months[itemMonth - 1];

      if (
        monthAbbreviation === months[currentMonth - 1] &&
        itemYear === selectedYear
      ) {
        monthControllerTotal = casedoneValue;
        monthPrintheadTotal = caseprocessingValue;
        monthPrinterTotal = casecancelValue;
      }
    });
  }, [barData, selectedYear]);

  const optionCase = {
    grid: {
      left: "2%",
      right: "0%",
      top: "9%",
      bottom: "7%",
    },
    xAxis: {
      type: "category",
      data: props.inputLabels,
      axisLabel: {
        show: true,
        textStyle: {
          color: "#333",
          fontFamily: "UTM avo",
          fontSize: 13,
          // fontWeight: 'bold'
        },
      },
    },
    yAxis: {
      type: "value",
      nameLocation: "center",
      axisLabel: {
        formatter: "{value}",
        fontSize: 8,
      },
    },
    tooltip: {
      trigger: "axis",
      formatter: function (params) {
        let result = "";

        //Kiểm tra xem params có phải là một mảng và có dữ liệu
        if (Array.isArray(params) && params.length > 0) {
          // Kiểm tra dữ liệu cho Controller
          //console.log(params);
          const caseActiveData = params.find((p) => p.seriesName === LABELS[3]);
          //console.log(controllerData);
          if (caseActiveData) {
            result += `<strong>${caseActiveData.name}</strong><br/>${
              LABELS[3]
            }: ${caseActiveData.value ? caseActiveData.value : 0}`;
          }
          // Kiểm tra dữ liệu cho Controller
          //console.log(params);
          const controllerData = params.find((p) => p.seriesName === LABELS[0]);
          //console.log(controllerData);
          if (controllerData) {
            if (result) result += "<br/>";
            result += `${LABELS[0]}: ${
              controllerData.value ? controllerData.value : 0
            }`;
          }

          // Kiểm tra dữ liệu cho Print head
          const printheadData = params.find((p) => p.seriesName === LABELS[1]);
          if (printheadData) {
            if (result) result += "<br/>"; // Thêm dòng mới nếu có dữ liệu cho Controller
            result += `${LABELS[1]}: ${
              printheadData.value ? printheadData.value : 0
            }`;
          }
          // Kiểm tra dữ liệu cho Printer
          const printerData = params.find((p) => p.seriesName === LABELS[2]);
          if (printerData) {
            if (result) result += "<br/>"; // Thêm dòng mới nếu có dữ liệu cho Controller
            result += `${LABELS[2]}: ${
              printerData.value ? printerData.value : 0
            }`;
          }
        }

        return result;
      },
    },
    legend: {
      show: true,
      data: LABELS,
      align: "left",
      padding: 5,
      itemGap: 15,
      textStyle: {
        fontSize: 15,
        fontFamily: "UTM Avo",
      },
    },
    series: [
      {
        name: LABELS[3], // Controller
        type: "bar",
        data: barData.map((item) => item.caseactive),
        itemStyle: {
          color: COLORS[3],
        },
      },
      {
        name: LABELS[0], // Controller
        type: "bar",
        data: barData.map((item) => item.casedone),
        itemStyle: {
          color: COLORS[0],
        },
      },
      {
        name: LABELS[1], // Printhead
        type: "bar",
        data: barData.map((item) => item.caseprocessing),
        itemStyle: {
          color: COLORS[1],
        },
      },
      {
        name: LABELS[2], // Printer
        type: "bar",
        data: barData.map((item) => item.casecancel),
        itemStyle: {
          color: COLORS[2],
        },
      },
    ],
  };
  return (
    <ResponsiveContainer className="bar-chart-container">
      <ReactECharts
        option={optionCase}
        style={{ height: 460, width: "100%" }}
      />
    </ResponsiveContainer>
  );
};
