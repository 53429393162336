import { useEffect, useState, useRef } from "react";
import { batch, useSelector } from "react-redux";

import CaseStaticsChart from "./Chart/CaseStaticsChart";
import KeyStaticsChart from "./Chart/KeyStaticsChart";
import React from "react";
import ReactECharts from "echarts-for-react";
import ReactDOM from "react-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import $ from "jquery";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { DatePicker, Flex, Space, Select, Collapse, Col } from "antd";
import IncrementalNumber from "./number";
import {
  PieChart,
  Pie,
  Cell,
  Legend,
  Tooltip,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  LineChart,
  Line,
  ResponsiveContainer,
  AreaChart,
  Area,
  ComposedChart,
  ScatterChart,
  Scatter,
} from "recharts";
import label from "../../inputs/label";
import { json } from "react-router-dom";
const RECORD_PER_PAGE = 10;

export default (props) => {
  const { functions, lang, proxy } = useSelector((state) => state);
  const { formatNumber } = functions;
  const _user = JSON.parse(localStorage.getItem("user")) || {};
  const { data, statis } = props;
  //console.log(props);
  const { display_name, type } = statis;
  const { headers, values } = data;
  const page = props.page;
  const _token = localStorage.getItem("_token");
  const [display, setDisplay] = useState(headers.slice(0, RECORD_PER_PAGE));
  const [currentPage, setCurrentPage] = useState(0);
  const [labeType, setLabelType] = useState([]);
  const totalPages = Math.ceil(headers.length / RECORD_PER_PAGE);

  var lastday = function (y, m) {
    // Create a new Date object representing the last day of the specified month
    // By passing m + 1 as the month parameter and 0 as the day parameter, it represents the last day of the specified month
    const dateOfMonth = new Date(y, m + 1, 0).getDate();
    const result = [];
    for (let i = 0; i < dateOfMonth; i++) {
      result.push(i + 1);
    }
    return result;
  };

  const MyBarChart1 = () => {
    // const COLORS = ["#4D90FE", "#96C291", "#E19898"];
    const COLORS = ["#4988ef", "#72c05d", "#ff7170"];
    const months = [
      lang["january"],
      lang["february"],
      lang["march"],
      lang["april"],
      lang["may"],
      lang["june"],
      lang["july"],
      lang["august"],
      lang["september"],
      lang["october"],
      lang["november"],
      lang["december"],
    ];

    // const LABELS = ["Controller", "Print head", "Printer"];

    const uniqueLabels = new Set();
    // data.headers.forEach((header) => {
    //   const parts = header.split(" ");
    //   if (parts.length > 2) {
    //     uniqueLabels.add(parts.slice(2).join(" "));
    //   }
    // });

    //const LABELS = [...uniqueLabels];
    const LABELS = ["Controller", "Printhead", "Printer"];
    // console.log(LABELS[2])

    // const years = [
    //   ...new Set(data.headers.map((header) => header.split(" ")[1])),
    // ];
    const currentYear = new Date().getFullYear();
    const [selectedYear, setSelectedYear] = useState(currentYear.toString());

    const [currentMonth, setSurrentMonth] = useState(new Date().getMonth() + 1);
    const [barData, setBarData] = useState([]);
    const [totalControllerForYear, setTotalControllerForYear] = useState(0);
    const [totalPrintheadForYear, setTotalPrintheadForYear] = useState(0);
    const [totalControllerForCurrentMonth, setTotalControllerForCurrentMonth] =
      useState(0);
    const [totalPrintheadForCurrentMonth, setTotalPrintheadForCurrentMonth] =
      useState(0);

    const [totalPrinterForYear, setTotalPrinterForYear] = useState(0);
    const [totalPrinterForCurrentMonth, setTotalPrinterForCurrentMonth] =
      useState(0);
    useEffect(() => {
      let yearControllerTotal = 0;
      let yearPrintheadTotal = 0;
      let yearPrinterTotal = 0;
      let monthControllerTotal = 0;
      let monthPrintheadTotal = 0;
      let monthPrinterTotal = 0;

      barData.forEach((item) => {
        //console.log(2, item);
        // Đảm bảo rằng các giá trị là hợp lệ, nếu không sẽ được đặt thành 0
        const controllerValue = Number.isFinite(item.controller)
          ? item.controller
          : 0;
        const printheadValue = Number.isFinite(item.printhead)
          ? item.printhead
          : 0;
        const printerValue = Number.isFinite(item.printer) ? item.printer : 0;

        yearControllerTotal += controllerValue;
        yearPrintheadTotal += printheadValue;
        yearPrinterTotal += printerValue;

        const monthAndYear = item.name.split(" ");
        const itemMonth = monthAndYear[0];
        const itemYear = monthAndYear[1];

        const monthAbbreviation = months[itemMonth - 1];

        if (
          monthAbbreviation === months[currentMonth - 1] &&
          itemYear === selectedYear
        ) {
          monthControllerTotal = controllerValue;
          monthPrintheadTotal = printheadValue;
          monthPrinterTotal = printerValue;
        }
      });
      setTotalControllerForYear(yearControllerTotal);
      setTotalPrintheadForYear(yearPrintheadTotal);
      setTotalPrinterForYear(yearPrinterTotal);
      setTotalControllerForCurrentMonth(monthControllerTotal);
      setTotalPrintheadForCurrentMonth(monthPrintheadTotal);
      setTotalPrinterForCurrentMonth(monthPrinterTotal);
    }, [barData, selectedYear]);

    const [selectedYearx, setYear] = useState();
    const [totalStatics, setTotalStatics] = useState({});
    useEffect(() => {
      localStorage.setItem(
        "SeletedYear",
        selectedYearx ? selectedYearx : selectedYear
      );
    }, [selectedYear, selectedYearx]);

    var weekOfYear = require("dayjs/plugin/weekOfYear");
    const isoWeek = require("dayjs/plugin/isoWeek");
    dayjs.extend(customParseFormat, weekOfYear, isoWeek);
    const zeroPad = (num, places) => String(num).padStart(places, "0");

    const [selectedMonth, setMonth] = useState();
    const [selectedWeek, setWeek] = useState();
    const [selectCus, setCus] = useState();

    function getDaysInWeek(weekNumber, year) {
      // Tạo một đối tượng Date từ năm và tuần đã cho
      const date = new Date(year, 0, (weekNumber - 1) * 7 + 1);

      // Lấy ngày đầu tiên trong tuần
      const firstDayOfWeek = new Date(
        date.setDate(date.getDate() - date.getDay())
      );

      // Tạo mảng để lưu trữ các ngày trong tuần
      const daysInWeek = [];

      // Lấy các ngày trong tuần
      for (let i = 0; i < 7; i++) {
        const day = new Date(firstDayOfWeek);
        day.setDate(firstDayOfWeek.getDate() + i);
        const formattedDate = `${day.getDate()}/${
          day.getMonth() + 1
        }/${day.getFullYear()}`;
        daysInWeek.push(formattedDate);
      }

      return daysInWeek;
    }
    //console.log(selectCus);
    const [dataCustomer, setDataCustomer] = useState(null);
    //const [prevDataCustomer, setPrevDataCustomer] = useState();
    const [lstSelectCustomer, setlstSelectCustomer] = useState([]);
    function getSelectCustomerList() {
      try {
        const requestBody = {
          checkUser: {
            username: "DIPES-TEAM",
            password: "a01555daf781180515dec9895c40f882",
          },
          checkCustomer: {
            username: "Mylan Digital Solution",
            password: "53e6086284353cb73d4979f08537d950", //storedPwdString,
          },
        };
        // Trước khi gọi API, đặt giá trị của tableDataProduct thành một mảng rỗng

        const response = fetch(
          `${proxy()}/api/32BE0BDC56861DE9432457412CDB2E54`,
          {
            headers: {
              Authorization: _token,
              "content-type": "application/json",
            },
            method: "POST",
            body: JSON.stringify(requestBody),
          }
        )
          .then((response) => {
            return response.json();
          })
          .then((resp) => {
            const { data } = resp;
            //console.log(data);
            if (data) setDataCustomer(data);
            //console.log(lstSelect);
          });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
    useEffect(() => {
      if (dataCustomer == null) {
        getSelectCustomerList();
        //setPrevDataCustomer(dataCustomer);
        //console.log(dataCustomer);
      }
      const lstSelect = [];
      if (dataCustomer) {
        dataCustomer.forEach((item) => {
          let selectValue = {};
          selectValue.value = item.CusN;
          selectValue.label = item.CusN;

          lstSelect.push(selectValue);
        });
      }
      //console.log(lstSelect);
      setlstSelectCustomer(lstSelect);
    }, [dataCustomer]);
    //console.log(getSelectCustomerList());
    const selectCustomer = () => {
      return (
        <Flex align="center" gap="small">
          <label>{lang["select a customer"]}</label>
          <Select
            showSearch
            onChange={(value) => {
              setCus(value);
              fetchData();
            }}
            allowClear
            style={{ width: 200 }}
            placeholder={lang["select a customer"]}
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            options={lstSelectCustomer}
          />
        </Flex>
      );
    };
    function disabledDate(current) {
      const currentYear = new Date().getFullYear();

      return current.year() > currentYear;
    }
    const SplitTimeSelect = () => {
      return (
        <>
          <Flex align="center" gap="small">
            <label>{lang["select"] + " " + lang["year"]}</label>
            <DatePicker
              format={"YYYY"}
              placeholder={lang["select"] + " " + lang["year"]}
              onChange={(value) => {
                dayjs(value).isValid()
                  ? setYear(dayjs(value).format("YYYY"))
                  : setYear(null);
                dayjs(value).isValid() ? setMonth(null) : setMonth(null);
                fetchData();
              }}
              picker="year"
              mode="year"
              disabledDate={disabledDate}
            />
            {selectedYearx ? (
              <>
                <label>{lang["month"]}</label>
                <DatePicker
                  format={"MM"}
                  placeholder={lang["select"] + " " + lang["month"]}
                  onChange={(value) => {
                    dayjs(value).isValid()
                      ? setMonth(dayjs(value).format("MM"))
                      : setMonth(null);
                    dayjs(value).isValid() ? setWeek(null) : setWeek(null);
                  }}
                  picker="month"
                  mode="month"
                />
              </>
            ) : null}
            {selectedMonth ? (
              <>
                <label>{lang["week"]}</label>
                <DatePicker
                  format={"wo"}
                  defaultValue={dayjs(
                    selectedMonth + "-" + selectedYearx,
                    "MM-YYYY"
                  )}
                  placeholder={lang["select"] + " " + lang["week"]}
                  onChange={(value) => {
                    dayjs(value).isValid()
                      ? setWeek(dayjs(value).week())
                      : setWeek(null);
                  }}
                  picker="week"
                  mode="week"
                />
              </>
            ) : null}
          </Flex>
        </>
      );
    };
    const searchPanel = () => {
      return (
        <>
          <div class="col-lg-12">
            <div class="white_shd full">
              <div class="row colum1">
                <Flex align="center" gap="small" justify="flex-start">
                  {_user.role === "mds_ad" && (
                    <>
                      <div class="col-md-3">{selectCustomer()}</div>
                    </>
                  )}
                  <div
                    class="col-md-7"
                    style={{ paddingBottom: "10px", textAlign: "right" }}>
                    <div
                      class="row"
                      style={{
                        textAlignLast: "center",
                        marginTop: "10px",
                        marginLeft: "10px",
                      }}>
                      {SplitTimeSelect()}
                    </div>
                  </div>
                  <div
                    class="col-md-2 fix-right"
                    style={{ paddingRight: "25px", textAlign: "right" }}>
                    <Flex align="center" gap="small" justify="flex-end">
                      <button
                        class="btn"
                        style={{
                          backgroundColor: "#4988ef",
                          color: "aliceblue",
                          margin: "5px",
                          height: "35px",
                        }}
                        onClick={handlefetch}>
                        <i
                          class="fa fa-search size-20 pointer mb-2 "
                          title="Search"></i>
                        <span style={{ marginLeft: "10px" }}>
                          {lang["filter data"]}
                        </span>
                      </button>
                    </Flex>
                  </div>
                </Flex>
              </div>
            </div>
          </div>
        </>
      );
    };
    const handlefetch = () => {
      fetchData();
      fetchDataKey();
    };
    //lấy số ngày trong 1 tháng
    const dates = lastday(
      selectedYearx ? selectedYearx : null,
      selectedMonth ? selectedMonth - 1 : null
    );
    //console.log(dates);
    const getLabels = () => {
      if (selectedWeek) return getDaysInWeek(selectedWeek, selectedYearx);
      else return selectedMonth && dates ? dates : months;
    };
    const chartLabel = getLabels();

    useEffect(() => {
      if (totalStatics != JSON.parse(localStorage.getItem("Statics"))) {
        setTotalStatics(JSON.parse(localStorage.getItem("Statics")));
      }
    }, [selectedYear]);
    const validLabelsCount = LABELS.filter(
      (label) => label !== undefined
    ).length;
    const columnWidth = 12 / validLabelsCount;
    //Lấy data cho biểu đồ key
    const [dataKey, setDataKey] = useState(null);
    const fetchCountKey = useRef(0);
    const fetchDataKey = async () => {
      try {
        setDataKey([]); //set dữ liệu null
        if (selectedWeek) {
          //F7A00EB2872208FEF48E3BAA69BE353B
          const requestBody = {
            checkUser: {
              username: "DIPES-TEAM",
              password: "a01555daf781180515dec9895c40f882",
            },
            checkCustomer: {
              username: "Mylan Digital Solution",
              password: "53e6086284353cb73d4979f08537d950",
            },
            selectedCustomer:
              _user.role !== "mds_ad"
                ? _user.Company
                : selectCus
                ? selectCus
                : "All",
            beginDate: selectedWeek
              ? getDaysInWeek(selectedWeek, selectedYearx)[0]
              : "",
            endDate: selectedWeek
              ? getDaysInWeek(selectedWeek, selectedYearx)[
                  getDaysInWeek(selectedWeek, selectedYearx).length - 1
                ]
              : "",
          };
          //console.log(requestBody);
          const response = await fetch(
            `${proxy()}/api/F7A00EB2872208FEF48E3BAA69BE353B`,
            {
              headers: {
                Authorization: _token,
                "content-type": "application/json",
              },
              method: "POST",
              body: JSON.stringify(requestBody),
            }
          );
          const resp = await response.json();
          const newData = resp["1TKTT"];
          //console.log(1, newData);
          //console.log(dataPrev);
          if (newData && newData !== data) {
            setDataKey(newData);
            fetchCountKey.current = 0;
            //setDataPrev(newData);
          } else {
            //setDataPrev(newData);
            fetchCountKey.current++;
          }
        } else if (selectedMonth) {
          const requestBody = {
            checkUser: {
              username: "DIPES-TEAM",
              password: "a01555daf781180515dec9895c40f882",
            },
            checkCustomer: {
              username: "Mylan Digital Solution",
              password: "53e6086284353cb73d4979f08537d950",
            },
            selectedCustomer:
              _user.role !== "mds_ad"
                ? _user.Company
                : selectCus
                ? selectCus
                : "All",
            selectedYear: selectedYearx ? selectedYearx : selectedYear,
            selectedMonth: selectedMonth ? selectedMonth : "01",
          };

          const response = await fetch(
            `${proxy()}/api/34B371C7BF265138AC38EBD83ABFA53E`,
            {
              headers: {
                Authorization: _token,
                "content-type": "application/json",
              },
              method: "POST",
              body: JSON.stringify(requestBody),
            }
          );
          const resp = await response.json();
          const newData = resp["1TKTT"];
          //console.log(1, newData);
          //console.log(dataPrev);
          if (newData && newData !== data) {
            setDataKey(newData);
            fetchCountKey.current = 0;
            //setDataPrev(newData);
          } else {
            //setDataPrev(newData);
            fetchCountKey.current++;
          }
        } else {
          const requestBody = {
            checkCustomer: {
              username: "Mylan Digital Solution",
              password: "53e6086284353cb73d4979f08537d950",
            },
            selectedCustomer:
              _user.role !== "mds_ad"
                ? _user.Company
                : selectCus
                ? selectCus
                : "All",
            selectedYear: selectedYearx ? selectedYearx : selectedYear,
          };
          //console.log(requestBody);
          const response = await fetch(
            `${proxy()}/api/6C53C1091FDA16DDA74C26A91F3C808B`,
            {
              headers: {
                Authorization: _token,
                "content-type": "application/json",
              },
              method: "POST",
              body: JSON.stringify(requestBody),
            }
          );
          const resp = await response.json();
          const newData = resp["1TKTT"];
          //console.log(2, newData);
          //console.log(dataPrev);
          if (newData && newData !== data) {
            setDataKey(newData);
            fetchCountKey.current = 0;
            //setDataPrev(newData);
          } else {
            //setDataPrev(newData);
            fetchCountKey.current++;
          }
        }
      } catch {}
      //});
    };
    useEffect(() => {
      if (fetchCountKey.current < 3) {
        // Replace 5 with your desired limit
        fetchDataKey();
      }
    }, [selectedWeek, selectedMonth, selectedYearx, selectCus]);
    //console.log(dataKey);
    function groupData(data) {
      const result = {};

      data.forEach((item) => {
        const { name, controller, printhead, printer } = item;
        if (!result[name]) {
          result[name] = { name, controller: 0, printhead: 0, printer: 0 };
        }
        if (controller !== undefined) result[name].controller = controller;
        if (printhead !== undefined) result[name].printhead = printhead;
        if (printer !== undefined) result[name].printer = printer;
      });

      return Object.values(result);
    }
    useEffect(() => {
      const filteredData = [];

      for (const key in dataKey) {
        const [month, year, type] = key.split(" ");
        const monthYear = `${month} ${year}`;
        let entry = { name: monthYear };
        if (type == "Controller") {
          entry.controller = dataKey[key];
        }
        if (type == "Print") {
          entry.printhead = dataKey[key];
        }
        if (type == "Printer") {
          entry.printer = dataKey[key];
        }
        //filteredData[monthYear][type] = data[key];
        //console.log(entry);
        filteredData.push(entry);
      }
      //console.log(filteredData);
      setBarData(groupData(filteredData));
    }, [dataKey, selectedYear]);
    //Lấy data cho biểu đồ case
    const [dataCase, setDataCase] = useState(null);
    const fetchCount = useRef(0);
    //console.log(getDaysInWeek(selectedWeek, selectedYearx));
    const fetchData = async () => {
      try {
        setDataCase([]);
        if (selectedWeek) {
          const requestBody = {
            checkUser: {
              username: "DIPES-TEAM",
              password: "a01555daf781180515dec9895c40f882",
            },
            checkCustomer: {
              username: "Mylan Digital Solution",
              password: "53e6086284353cb73d4979f08537d950",
            },
            selectedWeek: selectedWeek
              ? getDaysInWeek(selectedWeek, selectedYearx)
              : "",
            selectedCustomer:
              _user.role !== "mds_ad"
                ? _user.Company
                : selectCus
                ? selectCus
                : "All",
          };
          //console.log(requestBody);
          const response = await fetch(
            `${proxy()}/api/7263F524373B92D1513ED8AA590AEA1E`,
            {
              headers: {
                Authorization: _token,
                "content-type": "application/json",
              },
              method: "POST",
              body: JSON.stringify(requestBody),
            }
          );

          const resp = await response.json();
          //console.log(resp);
          if (resp.success == true) {
            const newData = resp["1TKTT"];
            //console.log(newData);
            //console.log(dataPrev);
            if (newData && newData !== data) {
              setDataCase(newData);
              fetchCount.current = 0;
              //setDataPrev(newData);
            } else {
              //setDataPrev(newData);
              fetchCount.current++;
            }
          }
        } else if (selectedMonth) {
          const requestBody = {
            checkUser: {
              username: "DIPES-TEAM",
              password: "a01555daf781180515dec9895c40f882",
            },
            checkCustomer: {
              username: "Mylan Digital Solution",
              password: "53e6086284353cb73d4979f08537d950",
            },
            selectedYear: selectedYearx ? selectedYearx : selectedYear,
            selectedCustomer:
              _user.role !== "mds_ad"
                ? _user.Company
                : selectCus
                ? selectCus
                : "All",
            selectedMonth: selectedMonth ? selectedMonth : "01",
          };
          const response = await fetch(
            `${proxy()}/api/5D0F9533BADB0FD52303119F23DC2299`,
            {
              headers: {
                Authorization: _token,
                "content-type": "application/json",
              },
              method: "POST",
              body: JSON.stringify(requestBody),
            }
          );

          const resp = await response.json();
          //console.log(resp);
          if (resp.success == true) {
            const newData = resp["1TKTT"];
            //console.log(newData);
            //console.log(dataPrev);
            if (newData && newData !== data) {
              setDataCase(newData);
              fetchCount.current = 0;
              //setDataPrev(newData);
            } else {
              //setDataPrev(newData);
              fetchCount.current++;
            }
          }
        } else {
          const requestBody = {
            checkUser: {
              username: "DIPES-TEAM",
              password: "a01555daf781180515dec9895c40f882",
            },
            checkCustomer: {
              username: "Mylan Digital Solution",
              password: "53e6086284353cb73d4979f08537d950",
            },
            selectedYear: selectedYearx ? selectedYearx : selectedYear,
            selectedCustomer:
              _user.role !== "mds_ad"
                ? _user.Company
                : selectCus
                ? selectCus
                : "All",
          };
          const response = await fetch(
            `${proxy()}/api/9A5A6BB26FE023CDDDA73E05A44A751F`,
            {
              headers: {
                Authorization: _token,
                "content-type": "application/json",
              },
              method: "POST",
              body: JSON.stringify(requestBody),
            }
          );

          const resp = await response.json();
          //console.log(resp);
          if (resp.success == true) {
            const newData = resp["1TKTT"];
            //console.log(newData);
            //console.log(dataPrev);
            if (newData && newData !== data) {
              setDataCase(newData);
              //fetchCount.current = 0;
              //setDataPrev(newData);
            } else {
              //setDataPrev(newData);
              //fetchCount.current++;
            }
          }
        }
        //console.log(requestBody);
      } catch {}
      //});
    };

    useEffect(() => {
      if (fetchCount.current < 5) {
        fetchData();
      }
    }, [selectedWeek, selectedMonth, selectedYearx, selectCus]);
    //console.log(selectedMonth);
    //console.log(dataCase);

    function gettotalcase(data) {
      const monthlyTotals = {};
      if (data) {
        for (const [key, value] of Object.entries(data)) {
          const month = key.split(" ")[0];
          if (!monthlyTotals[month]) {
            monthlyTotals[month] = 0;
          }
          monthlyTotals[month] += value;
        }
      }
      const total = Object.values(monthlyTotals).reduce(
        (acc, value) => acc + value,
        0
      );
      return total;
    }
    //Kiểm tra nếu chọn tháng và tuần thì ẩn
    const checkShowPrintHeadInfo = () => {
      if ((!selectedMonth && !selectedWeek) || !selectedMonth) {
        return (
          <ul>
            <li>
              <span>
                <strong class="f-24">
                  {" "}
                  {totalPrintheadForCurrentMonth || 0}
                </strong>
              </span>
              <span class="mt-2"> {months[currentMonth - 1]}</span>
            </li>
            <li>
              <span>
                <strong class="f-24"> {totalPrintheadForYear || 0}</strong>
              </span>
              <span class="mt-2">
                {" "}
                {lang["year"]} {selectedYear}
              </span>
            </li>
          </ul>
        );
      } else {
        return (
          <ul>
            <li style={{ width: "-webkit-fill-available" }}>
              <span>
                <strong class="f-24"> {totalPrintheadForYear || 0}</strong>
              </span>
              <span class="mt-2">
                {" "}
                {
                  months[
                    selectedMonth ? selectedMonth.replace(/^0+/g, "") - 1 : 0
                  ]
                }
              </span>
            </li>
          </ul>
        );
      }
    };
    //Kiểm tra nếu chọn tháng và tuần thì ẩn
    const checkShowControllerInfo = () => {
      if ((!selectedMonth && !selectedWeek) || !selectedMonth) {
        return (
          <ul>
            <li>
              <span>
                <strong class="f-24">
                  {" "}
                  {totalControllerForCurrentMonth || 0}
                </strong>
              </span>
              <span class="mt-2">{months[currentMonth - 1]}</span>
            </li>
            <li>
              <span>
                <strong class="f-24"> {totalControllerForYear || 0}</strong>
              </span>
              <span class="mt-2">
                {" "}
                {lang["year"]} {selectedYear}
              </span>
            </li>
          </ul>
        );
      } else {
        return (
          <ul>
            <li style={{ width: "-webkit-fill-available" }}>
              <span>
                <strong class="f-24"> {totalControllerForYear || 0}</strong>
              </span>
              <span class="mt-2">
                {" "}
                {
                  months[
                    selectedMonth ? selectedMonth.replace(/^0+/g, "") - 1 : 0
                  ]
                }
              </span>
            </li>
          </ul>
        );
      }
    };
    //Kiểm tra nếu chọn tháng và tuần thì ẩn
    const checkShowPrinterInfo = () => {
      if ((!selectedMonth && !selectedWeek) || !selectedMonth) {
        return (
          <ul>
            <li>
              <span>
                <strong class="f-24">
                  {" "}
                  {totalPrinterForCurrentMonth || 0}
                </strong>
              </span>
              <span class="mt-2"> {months[currentMonth - 1]}</span>
            </li>
            <li>
              <span>
                <strong class="f-24"> {totalPrinterForYear || 0}</strong>
              </span>
              <span class="mt-2">
                {" "}
                {lang["year"]} {selectedYear}
              </span>
            </li>
          </ul>
        );
      } else {
        return (
          <ul>
            <li style={{ width: "-webkit-fill-available" }}>
              <span>
                <strong class="f-24"> {totalPrinterForYear || 0}</strong>
              </span>
              <span class="mt-2">
                {" "}
                {
                  months[
                    selectedMonth ? selectedMonth.replace(/^0+/g, "") - 1 : 0
                  ]
                }
              </span>
            </li>
          </ul>
        );
      }
    };
    //
    return (
      <>
        <div class="row column1 mb-2">{searchPanel()}</div>
        <div class="row column1 mb-2">
          <div class="col-lg-6 ">
            <div class="white_shd full">
              <div class="tab_style2 layout2">
                <div class="tabbar">
                  <div class="full graph_head d-flex text-center">
                    <div class="heading1 margin_0 ">
                      <h5>
                        {lang["license create"]}{" "}
                        {selectedYearx ? selectedYearx : selectedYear}
                        {selectedMonth ? " " + selectedMonth : ""}
                        {selectedWeek ? " week " + selectedWeek : ""}
                        <br />
                        {lang["total quantity"]}{" "}
                        {totalControllerForYear +
                          totalPrintheadForYear +
                          totalPrinterForYear || 0}
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
              <div class="table_section padding_infor_info_layout_chart ">
                <div className="bar-container">
                  {/* <div className="select-container">
                    {years.length > 1 && (
                      <select
                        className="form-control pointer"
                        value={selectedYear}
                        onChange={(e) => setSelectedYear(e.target.value)}>
                        {years.map((year) => (
                          <option key={year} value={year}>
                            {year}
                          </option>
                        ))}
                      </select>
                    )}
                  </div> */}
                  {/* <ResponsiveContainer className="bar-chart-container">
                    <ReactECharts
                      option={option}
                      style={{ height: 460, width: "100%" }}
                    />
                  </ResponsiveContainer> */}
                  <KeyStaticsChart inputLabels={chartLabel} data={dataKey} />
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6 ">
            <div class="white_shd full">
              <div class="tab_style2 layout2">
                <div class="tabbar">
                  <div class="full graph_head d-flex text-center">
                    <div class="heading1 margin_0 ">
                      <h5>
                        {" "}
                        {lang["case create in"]}{" "}
                        {selectedYearx ? selectedYearx : selectedYear}
                        {selectedMonth ? " " + selectedMonth : ""}
                        {selectedWeek ? " week " + selectedWeek : ""}
                        <br />
                        {lang["total case"]} {gettotalcase(dataCase)}
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
              <div class="table_section padding_infor_info_layout_chart ">
                <div className="bar-container">
                  {/* <div className="select-container">
                    {years.length > 1 && (
                      <select
                        className="form-control pointer"
                        value={selectedYear}
                        onChange={(e) => setSelectedYear(e.target.value)}>
                        {years.map((year) => (
                          <option key={year} value={year}>
                            {year}
                          </option>
                        ))}
                      </select>
                    )}
                  </div> */}
                  <CaseStaticsChart inputLabels={chartLabel} data={dataCase} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row column1 social_media_section">
          <div class="col-md-4 col-lg-4 ">
            <div class="full socile_icons controller margin_bottom_30">
              <div class="social_icon color-whiteh5">{LABELS[0]}</div>
              <div
                class="social_cont"
                style={{
                  paddingBottom: "25px",
                  paddingTop: "35px",
                  fontSize: "24px",
                }}>
                {checkShowControllerInfo()}
              </div>
            </div>
          </div>

          <div class="col-md-4 col-lg-4">
            <div class="full socile_icons printhead margin_bottom_30">
              <div class="social_icon color-whiteh5">{LABELS[1]}</div>
              <div
                class="social_cont"
                style={{
                  paddingBottom: "25px",
                  paddingTop: "35px",
                  fontSize: "24px",
                }}>
                {checkShowPrintHeadInfo()}
              </div>
            </div>
          </div>
          <div class="col-md-4 col-lg-4">
            <div class="full socile_icons printer margin_bottom_30">
              <div class="social_icon color-whiteh5">{LABELS[2]}</div>
              <div
                class="social_cont"
                style={{
                  paddingBottom: "25px",
                  paddingTop: "35px",
                  fontSize: "24px",
                }}>
                {checkShowPrinterInfo()}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <div class="col-md-12">
        <div class="tab-content">
          <div class="col-md-12">
            <MyBarChart1 />
          </div>
        </div>
      </div>
    </>
  );
};
