export default {
  // lang
  vi: "Vietnamese",
  en: "English",
  signin: "Sign In",
  account: "Account",
  // "password": "Password",
  "forgot password": "Forgot password ?",
  "remember me": "Remember me",
  online: "Online",
  search: "Search",
  result: "Result",
  // "results": "results",
  general: "General",
  "my profile": "My Profile",
  settings: "Settings",
  help: "Help",
  signout: "Sign Out",
  dashboard: "Dashboard",
  home: "Home",
  diagram: "Diagram",
  "user info": "Hi",
  "projects manager": "Project Manager",
  "project list": "Project List",
  "accounts manager": "Accounts Management",
  profile: "Profile",
  "accounts list": "Account list",
  report: "Reports",
  tasks: "Tasks",
  task: "Task",
  "projects worked": "Projects worked on",
  "profile user ": "Account information",
  "profile user update ": "Update information",
  "min ago": "min ago",
  statistic: "Statistic",
  workflow: "Workflow",
  "about us": "About Us",
  contacts: "Contacts",
  404: "PAGE NOT FOUND",

  "settings.title": "Settings",
  "settings.desc": "Application global settings",
  "settings.languages": "Languages",
  "settings.color": "Colors",
  "settings.color button": "Change",
  //status
  initialization: "Initialization",
  implement: "Implement",
  deploy: "Deploy",
  complete: "Complete",
  pause: "Pause",
  //description
  description: "Description",
  "project.info": "Project Information",
  "project.deploy": "Deployment website information",
  "data management": "Data management",
  //Project
  projects: "Projects",
  "projects.title": "Projects",
  "projects.desc": "Project manager",
  "project_detail.title": "Project detail",
  //List project
  projectcode: "Project Code",
  projectmanager: "Project Manager",
  projectprocess: "Project progress",
  projectmember: "Members",
  projectempty: "No members yet",
  createby: "Create by",
  by: " by ",

  //add project
  addproject: "Add proejct",
  projectname: "Project name",
  "p.projectname": "Enter a project name",
  // "projectcode": "Project code",
  "p.projectcode": "Enter the project code",
  projectstatus: "Status",
  "p.projectstatus": "Select status",
  projectrole: "Managers",
  "p.projectrole": "Choose a manager",
  projectdescripton: "Project description",
  "p.projectdescripton": "Enter project description",
  // "projectmember": "Project members",
  //update project
  updateproject: "Project update",
  //Task
  tasklist: "List of tasks",
  detailtask: "Task Detail",
  addtask: "Add Task",
  taskname: "Task name",
  "p.taskname": "Enter task name",
  taskstatus: "Status",
  task_priority: "Priority",
  taskmember: "Members",
  "p.description": "Description of the task",
  updatestatus: "Status updates",
  viewdetail: "View detail",
  delete: "Delete",
  edit: "Edit",
  edittask: "Edit task",
  duty: "Duty",
  confirm: "Confirm",

  //Managers
  editmanager: "Edit a project manager",
  //Members
  members: "Members",
  //button project
  buttondetail: "View details",
  //error project
  "error.project_name": "Please enter your project name",
  "error.project_status": "Please select project status",

  //Version
  version: "Version",
  versionupdate: "Version update",
  versionname: "Version name",
  "p.versionname": "Enter an version name",
  "p.versiondescription": "Enter a description",
  //User
  "users.title": "Users",
  "users.desc": "Users management",
  //Group user
  administrator: " Administrator",
  uprojectmanager: " Operator",
  normal: " Normal",
  monitor: "Monitor Staff",
  //list
  // "username": "Username",
  // "permission": "Permission",
  time: "Time",
  // modal add user,
  "btn.create": "Create new",
  // "btn.close": "Close",
  "adduser.title": "Add user",
  "profile.title": "Update profile",
  fullname: "Fullname",
  "p.fullname": "Enter your full name",
  username: "Username",
  "p.username": "Enter your username",
  password: "Password",
  "p.password": "Enter password",
  "re-password": "Confirm password",
  "p.re-password": "Re-enter password",
  email: "Email",
  "p.email": "Enter an email account",
  phone: "Phone number",
  "p.phone": "Enter phone number",
  permission: "Permission",
  "p.permission": "Select permissions",
  address: "Address",
  "p.address": "Enter the address",
  note: "Note",
  "p.note": "Type a note",
  // error user
  "error.fullname": "Please enter your full name",
  "error.username": "Please enter your username",
  "error.password": "Please enter your password",
  "error.confirmpassowrd": "Password mismatch",
  "error.email": "Please enter your email",
  "error.vaildemail": "Invalid email",
  "error.phone": "Please enter your phone number",
  "error.vaildphone": "Invalid phone number",
  "error.permission": "Please select permissions",
  "error.address": "Please enter the address",
  // modal edit user
  "btn.update": "Save",
  // "btn.create": "Create new",
  "btn.close": "Close",
  "edituser.title": "Update",
  // logs
  "log.information": "Information",
  "log.warning": "Warning",
  "log.error": "Error",
  "log.title": "LOGS",
  "log.statis": "Statistics",
  "log.type": "Type",
  "log.selecttype": "Select type",
  "log.daystart": "Start date",
  "log.dayend": "End date",
  "log.listlog": "Log list",
  "log.no": "No.",
  "log.id": "ID",
  "log.listtitle": "Title",
  "log.dayupdate": "Date",
  "log.action": "Action",
  "btn.ok": "Filter",
  "btn.clear": "Clear",
  "btn.viewdetail": "View details",
  detaillog: "Log details",
  "log.create_user": "Executor",
  "log.create_at": "Creation date",
  //page
  show: "Showing",
  of: "of",
  results: "results",
  supervisor: "Supervisor",
  deployers: "Deployers",
  "btn.cancel": "Cancel",
  "btn.addfield": "Add new field",
  export: "Export",
  "export-to-excel": "Export to Excel",
  activation: "Activation",
  success: "Success",
  "success.content": "Successful data import",
  faild: "Failed",
  "faild.content": "Data import failed",
  format: "Invalid file format",
  error: "Error",
  "faild.format": " Incorrect formatting, please select again!",
  "select file": "Choose File",
  "file name": "File name",
  "type file": "File format",
  "create account": "Create account",
  "manage data": "Manage Data",
  // "create": "Create",
  MAC: "This server MAC address",
  "active now": "Active now",
  "select fields": "Select fields",
  "select statistic fields ": "Select statistic fields",
  "preview data": "Excel data preview",
  "export.content.error": "Please select at least one field before exporting.",
  "success.add": "Add success data",
  "fail.add": "Add data failed",
  "success.update": "Successful update",
  "fail.update": "Update failed",
  // "confirm": "Confirm",
  "confirm.content": "Are you sure you want to delete this field?",
  "btn.delete": "Delete",
  "btn.cencel": "Remove",
  "success.delete": "Successful deletion",
  "fail.delete": "Deletion failed",
  "fail.null": "There are some fields that are not left blank",
  "fail.active": "Product has not been activated yet",
  "preview.content": "Please select at least one field to view sample data",
  "site map": "Sitemap",
  "not found user": "No users yet",
  "not found data": "Not found",
  "not found": "Not found",
  "activate.key": "Activation key",
  "profile.empty": "Please fill in the information",
  create: "Add",
  update: "Update",
  "not found config": "No Database and Api configuration found",
  "not found update": "No update features found",
  api: " Api Configuration",
  database: " Database Configuration",
  "error.email_invalid": "Invalid Email Address",
  "error.phone_invalid": "Invalid Phone Number",
  choose: "Choose",
  "select export type": "Select export type",
  "import data": "Import data",
  "export sample data": "Export sample data",
  "selected file": "Selected file",
  size: "Size",
  type: "Type",
  "check file": "Only .csv, .xlsx, and .xls file formats are accepted",
  loading: "Loading...",
  searching: "Searching...",
  import: "Import",
  "error.import.primarykey": "Primary key error",
  "error.import.duplicate": "Duplicate errors",
  "error.import.foreign": "Foreign key error",
  selectall: "Select all",
  imported: "In Progress",
  "error.format": "The imported file is not in the correct format",
  "import.complete": "Import complete",
  "import.text": "Data was imported successfully",
  "total.line": "Total number of lines",
  "total.imported": "Number of lines imported",
  "total.error": "Number of error lines",
  "total.time": "Time",
  minute: "minute",
  back: "Back",
  copied: "Copied!",
  "Number of rows exceeds the allowable limit":
    "Number of rows exceeds the allowable limit",
  "File size exceeds the allowable limit":
    "File size exceeds allowable limit (Up to 120MB)",
  export_excel_csv: "Export Excel/CSV",
  "export data example": "Export sample file",
  // "import data": "Import Data",
  value: "Value",
  criteria: "Criteria",
  importing: "Importing data",
  "privileges manager": "Permission Management",
  avatar: "Avatar",
  "table name": "Table name",
  privileges: "Account privileges",
  "privileges username": "Account name",
  read: "Read",
  write: "Write",
  modify: "Modify",
  purge: "Purge",
  "erorr pk fk": "primary and foreign key errors",
  "erorr fk": "Foreign key errors",
  "erorr pk": "Primary key errors",
  "char error": "Exceeding the allowed number of characters",
  "signout account": "Account signed another device",
  notification: "Notification",
  january: "January",
  february: "February",
  march: "March",
  april: "April",
  may: "May",
  june: "June",
  july: "July",
  august: "August",
  september: "September",
  october: "October",
  november: "November",
  december: "December",
  month: "Month",
  year: "Year",
  "no data": "No data available",
  next: "Next",
  "error.file": "Please select a .txt file!",
  "correct format": "The file content is not in the correct format!",
  back: "Back",
  "create key": "Create Key",
  "success create key": "Create license success",
  "change password": "Change Password",
  "new password": "New password",
  "p.new password": "Enter the new password",
  "old password": "Old password",
  "p.old password": "Enter the old password",
  "re password": "Re-enter password",
  "p.re password": "Re-enter the new password",
  change: "Change",
  "vaild password": "Vui lòng nhập mật khẩu cũ",
  "error.input": "This field can't be blank",
  duPassword: "Please do not reuse old passwords",
  validPassword: "The password re-entered does not match",
  "success.password":
    "Your password has been updated. Please log in again to continue using the service with the new security details",
  "faild.password": "Incorrect password",
  "update.config": "Update information",
  "enter serialnumber": "Enter Serial Number",
  "export to file": "Export file",
  step: "Step",
  "upload file": "Upload File",
  "get data": "Retrieve Data",
  "get result": "Get Results",
  "note not share": "Note: Do not share this key with anyone not authorized.",
  "create key success": "Successfully generate activation key!",
  "error.serial": "Only characters from A-Z and numbers from 0-9 are allowed",
  "no info": "Information not updated",
  "faild key success": "Activation key generation failed!",
  "total quantity": "Total key",
  "license create": "License created in",
  "error.number": "Minimum 8 and maximum 15 characters",
  "export file": "Export",
  "wrong account": "Incorrect username or password.",
  "wrong info": "Please enter both username and password.",
  "failed.password": "Password change failed",
  "wrong password": "Incorrect old password",
  "mins ago": "mins ago",
  "just now": "just now",
  "secs ago": "secs ago",
  "hours ago": "hours ago",
  "days ago": "days ago",
  "months ago": "months ago",
  "years ago": "years ago",
  "success create": "Created successfully",
  "faild create": "Create failed",
  "post your case": "POST YOUR CASE",
  "sorted by": "Sorted by",
  today: "Today",
  newest: "Newest",
  oldest: "Oldest",
  "latest support on": "Latest support on ",
  "Posted on": "Posted on",
  DISCUSSION: "DISCUSSION",
  "SUPPORT QUALITY": "SUPPORT QUALITY",
  total: "Total",
  "case(s)": "case(s)",
  "ISSUE DESCRIPTION": "Issue description",
  "SUGGESTED SOLUTION": "Suggested solution",
  "PRODUCT INFORMATION": "Product information",
  "ADD PRODUCT INFORMATION": "Add product information",
  "Type a message": "Type a message ...",
  "You have not rated the quality of support":
    "You have not rated the quality of support",
  "rate now": "Rate now",
  "APPRICIATE THE SERVICE QUALITY": "APPRICIATE THE SERVICE QUALITY",
  "Let us know how you feel": "Let us know how you feel",
  "Last updated by": "Last updated by",
  "Submit Review": "Submit",
  "Case title, case description, or anything": "Case title, case description",
  "Show more": "Show more",
  "send message": "Send Message",
  attachment: "Attachment",
  "new case": "New Case",
  "case title": "Case title",
  "case type": "Case type",
  "product name": "Product name",
  "CHOOSE FILE": "CHOOSE FILE",
  "submit case": "Submit case",
  "Click to change image": "Click to change image",
  "Case Update": "CASE UPDATE",
  "Enter case title": "Enter case title",
  "Enter product name": "Enter product name",
  "p.issue": "Enter issue description (Max 2000 characters)",
  "No attachment": "No attachment",
  "Choose Image": "Select Image",
  "no image case": "No image case",
  "No data avasilable": "No data available",
  "success.rate": "Successfully submitted the review",
  "File is too large": "Total file size exceeds 20MB",
  "controller error": "Controller error:",
  "printhead error": "Printhead error ",
  "printer error": "Printer error:",
  ERROR0: "None Error",
  ERROR1: "Device info not found",
  ERROR2: "Invalid UUID",
  ERROR3: "Data empty",
  "Unknown error": "Unknown error",
  "List of Errors": "List of Errors",
  "Please check the activation file": "Please check the activation file",
  "Click to preview": "Click to preview",
  "delete image": "Delete Image",
  "Drag and drop images here": "Drag and drop (Image, Video, Pdf, Excel) here",
  "Only image, video, PDF, and Excel files are accepted":
    "Only image, video, PDF, and Excel files are accepted",
  "Please upload an image file.": "Please upload an image file.",
  Notification: "Notification",
  expired: "Session expired. Please log back in to continue.",
  withdraw: "Information can only be updated within 24 hours of submission.",
  "Drag and drop images here and click":
    "Drag and drop here or click to select images",
  "case image": "Case image",
  "Warranty Claim": "Warranty claim",
  "Case Suggest": "Case suggest",
  "Possible Feature": "Possible feature",
  maximum: "Maximum",
  "add row": "Add row",
  "import file": "Import file",
  "sample file": "Sample file",
  "Timeout expired":
    "Connection pool timeout: All connections are in use or the max pool size has been reached",
  "format-excel-sample":
    "The file structure does not match the expected template",
  "issue part": "Issue Part",
  "hardware category": "Hardware Category",
  "case no": "Case Number",
  "case by": " Case By",
  "partner name": "Sub Agent",
  "company name": "Company Name",
  "case status": "Case Status",
  "date occur": "Date Occurred",
  "issue classifi": "Issue Classification",
  "trouble category": "Troubleshooting Category",
  "end user": "End User",
  "case serial": "Case Serial",
  "factory info": "Factory Information",
  "new printer lot": "New Printer Lot Board",
  "new replaced item": "New Replaced Items",
  "origional lot": "Origional Lot Board",
  "returned item": "Returned Items",
  "shipping info": "Shipping Information",
  component: "Component Replaced",
  "add component": "Add Conponent Replace",
  "advance info": "Advanced Information",
  "key detail": "Key Details",
  "case detail": "Cases Details",
  "case processing": "Cases Processing",
  "case done": "Cases Resolved",
  "case failed": "Cases Cancel",
  "customer name": "Customer Name",
  "ad dashboard": "Administrator Dashboard",
  select: "Select",
  year: "year",
  month: "month",
  "select a customer": "Select a customer",
  "filter data": "Search Data",
  week: "Week",
  //
  status: "Status",
  "case name": "Case Name",
  content: "Content",
  "date create": "Date Created",
  "case id": "Case ID",
  "total case": "Total Case",
  "ip info": "IP Information",
  "user create": "User Create",
  "request quantity": "Request Quantity",
  location: "Location",
  "case create in": "Case Create in",
  "switch view": "Switch view: ",
  "key details": "Activation key",
  "device details": "Activation device",
};
