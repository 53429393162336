import copy from "copy-to-clipboard";
import { makeArray } from "jquery";
import { useState, useEffect, useRef, React, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { Tooltip, Pagination, Flex } from "antd";

function Active_Helpdesk() {
  const [isActivated, setIsActivated] = useState(false);
  const { lang, proxy, auth, functions } = useSelector((state) => state);
  const stringifiedUser = localStorage.getItem("user");
  const _user = JSON.parse(stringifiedUser) || {};
  const username =
    _user.username === "administrator"
      ? "Mylan Digital Solution"
      : _user.username;
  const storedPwdString = localStorage.getItem("password_hash");
  const { openTab, renderDateTimeByFormat } = functions;
  const checkDetail = 1; //page.components?.[0]?.api_detail;
  // lấy dữ liệu
  const [previousdata, setPreviousValue] = useState(null);
  const [data, setData] = useState([]);
  let CompanyName = localStorage.getItem("CompName");
  const fetchCount = useRef(0);
  //console.log(data);
  const fetchData = async () => {
    try {
      const requestBody = {
        checkCustomer: {
          username: CompanyName,
          password: "53e6086284353cb73d4979f08537d950",
        },
      };

      const response = await fetch(
        `${proxy()}/api/DF1C445C0888F98730C50AD7D95294C9`,
        {
          headers: {
            Authorization: _token,
            "content-type": "application/json",
          },
          method: "POST",
          body: JSON.stringify(requestBody),
        }
      );
      const resp = await response.json();
      const newData = resp.data;
      //console.log(newData);
      //console.log(dataPrev);
      if (newData != data) {
        setData(filterbytype(newData, actionKey));
        fetchCount.current++;
      } else {
        fetchCount.current = 0;
      }
    } catch {}
  };

  const filterbytype = (data, key) => {
    const filterData = [];
    switch (key) {
      case "CaseDetail": {
        return data;
      }
      case "CaseProcessing": {
        data.forEach((element) => {
          if (element.ST === "In Progress") {
            filterData.push(element);
          }
        });
        return filterData;
      }
      case "CaseDone": {
        data.forEach((element) => {
          if (element.ST === "Resolved") {
            filterData.push(element);
          }
        });
        return filterData;
      }
      case "CaseFailed": {
        data.forEach((element) => {
          if (element.ST === "Cancel") {
            filterData.push(element);
          }
        });
        return filterData;
      }
    }
  };
  useEffect(() => {
    // Check if the fetch count has reached the limit
    if (fetchCount.current < 2) {
      // Replace 5 with your desired limit
      //console.log(fetchCount.current);
      fetchData();
    }
  }, [data]);

  //lấy cái key xem nó cần hiển thị cái gì
  const actionKey = localStorage.getItem("Key");
  const _token = localStorage.getItem("_token");

  //const [apiData, setApiData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dataTable_id, setDataTableID] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [apiDataName, setApiDataName] = useState([]);
  const [inputValues, setInputValues] = useState({});
  const [searchValues, setSearchValues] = useState({});
  //const page = props.page;
  //console.log(page);
  const rowsPerPage = 12;
  const indexOfLast = currentPage * rowsPerPage;
  const indexOfFirst = indexOfLast - rowsPerPage;
  const filteredData = useMemo(() => {
    if (Object.keys(searchValues).length === 0) {
      return data;
    }
    return data.filter((row) =>
      Object.entries(searchValues).every(([key, value]) =>
        row[key]?.toString().toLowerCase().includes(value?.toLowerCase())
      )
    );
  }, [searchValues, data]);

  const current = filteredData.slice(indexOfFirst, indexOfLast);

  //console.log(filteredData);
  const mappedcurrent = current?.map((item) => ({
    Id: item["Id"],
    col1: item["CID"],
    col2: item["CN"],
    col3: item["CT"],
    col4: item["T1"],
    col5: item["ST"],
  }));

  //console.log(current);
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  // useEffect(() => {
  //   if (previousdata != data) {
  //     setPreviousValue(data);
  //     setData(mappedfilter);
  //     //console.log(mappedArray);
  //   }
  // }, [data]);

  // useEffect(() => {
  //   if (page) {
  //     setApiData([]);
  //   }
  // }, [page, dataTable_id]);

  const totalPages = Math.ceil(filteredData.length / rowsPerPage);
  //console.log(1, filteredData.length);
  //console.log(totalPages);

  const [isActive, setIsActive] = useState(false);
  const handleSubmit = (e) => {
    e.preventDefault();
    setIsActivated(true);
    // TODO: Thêm mã để xử lý thông tin form và tạo mã kích hoạt
  };
  let navigate = useNavigate();
  const goToPage = (label) => {
    navigate(`/` + label);
  };
  let totalcase = localStorage.getItem("totalcase");
  let totalcaseprocessing = localStorage.getItem("totalcaseprocessing");
  let totalcasedone = localStorage.getItem("totalcasedone");
  let totalcasefailed = localStorage.getItem("totalcasefailed");
  const copyToClipboard = () => {
    const copyText = document.getElementById("activationKey");
    copyText.select();
    document.execCommand("copy");
    alert("Đã sao chép mã kích hoạt!");
  };
  const [isCopied, setIsCopied] = useState(false);

  const handleInputChange = (fomular_alias, value) => {
    setInputValues((prevValues) => ({
      ...prevValues,
      [fomular_alias]: value,
    }));
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === 13 || event.keyCode === 8) {
      handleSearchClick();
    }
  };

  const handleSearchClick = () => {
    setCurrentPage(1);
    setSearchValues(inputValues);

    setIsActive(true);
    setTimeout(() => {
      setIsActive(false);
    }, 300);
  };
  const handleCopy = (event) => {
    event.preventDefault();
    copy();

    setIsCopied(true);

    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };
  //
  //mở trang tạm
  const viewpageSample = (row) => {
    //console.log(row);
    goToPage(`CaseDetailViewer/case/detail-case/${row}`);
    setSelectedCaseID(row);
  };

  const lastRowFitHeight = () => {
    return "320px";
  };
  function formatPrinterKey(key) {
    return key.match(/.{1,4}/g).join("-");
  }
  const setSelectedCaseID = (caseid) => {
    localStorage.setItem("selectedCaseDetail", caseid);
  };
  const rawKey = "PRTA123B45PRTA123B45";
  const formattedKey = formatPrinterKey(rawKey);
  const tableTile = (key) => {
    switch (key) {
      case "CaseDetail":
        return (
          <>
            <label class="text-general-nonebold">Total</label>
            <label class="text-totalcase">{totalcase ? totalcase : 0}</label>
            <label class="text-general-nonebold">(in Processing</label>
            <label class="text-totalcaseprocessing">
              {totalcaseprocessing ? totalcaseprocessing : 0}{" "}
            </label>
            <label class="text-general-nonebold">,Resolved</label>
            <label class="text-totalcasedone">
              {totalcasedone ? totalcasedone : 0}
            </label>
            <label class="text-general-nonebold">,Cancel</label>
            <label class="text-totalcasefailed">
              {totalcasefailed ? totalcasefailed : 0}
            </label>
            )
          </>
        );
      case "CaseProcessing":
        return (
          <>
            <div class="col-md-3">
              <div class="row">
                <label class="text-general-nonebold">
                  Total Case Processing
                </label>
                <label class="text-totalcaseprocessing">
                  {totalcaseprocessing ? totalcaseprocessing : 0}
                </label>
              </div>
            </div>
          </>
        );
      case "CaseDone":
        return (
          <>
            <div class="col-md-3">
              <div class="row">
                <label class="text-general-nonebold">Total Case Resolved</label>
                <label class="text-totalcasedone">
                  {totalcasedone ? totalcasedone : 0}
                </label>
              </div>
            </div>
          </>
        );
      case "CaseFailed":
        return (
          <>
            <div class="col-md-3">
              <div class="row">
                <label class="text-general-nonebold">Total Case Cancel</label>
                <label class="text-totalcasefailed">
                  {totalcasefailed ? totalcasefailed : 0}
                </label>
              </div>
            </div>
          </>
        );
    }
  };
  const getColorStatus = (key) => {
    switch (key) {
      case "Active":
        return <label style={{ fontWeight: "bold" }}>{key}</label>;
      case "In Progress":
        return <label class="text-totalcaseprocessing">{key}</label>;
      case "Resolved":
        return <label class="text-totalcasedone">{key}</label>;
      case "Cancel":
        return <label class="text-totalcasefailed">{key}</label>;
    }
  };
  const [heightChat] = useState(0);
  const windowHeightRespon = window.innerHeight;
  const calculateHeight = () => {
    let baseHeight = windowHeightRespon;
    // console.log("Chieu cao man hinh:", baseHeight)
    const maxHeightReduction = 96 - 40; // Giới hạn giảm chiều cao tối đa

    if (heightChat > 40) {
      const heightReduction = Math.min(heightChat - 40, maxHeightReduction);
      baseHeight -= heightReduction;
    }
    // Trừ đi imageAdjustment trước khi áp dụng giới hạn tối thiểu 588
    // Đảm bảo baseHeight không thấp hơn 588
    baseHeight = Math.max(baseHeight, 585);
    return `${baseHeight}`;
  };
  function TextAbstract(text, length) {
    if (text == null) {
      return "";
    }
    if (text.length <= length) {
      return text;
    }
    text = text.substring(0, length);
    return text + "...";
  }

  return (
    <>
      <div class="col-md-12" style={{ height: calculateHeight() }}>
        <div class="col-md-12">
          <div class="row">{tableTile(actionKey)}</div>
          <div class="table-responsive">
            <table class="table table  ">
              <thead>
                <tr class="color-tr" style={{ height: "40px" }}>
                  <th
                    class="font-weight-bold "
                    style={{ width: "50px", minWidth: "50px" }}
                    scope="col">
                    {lang["log.no"]}
                  </th>
                  <th
                    class="font-weight-bold"
                    style={{ width: "200px" }}
                    scope="col">
                    <div className="th-container">{lang["case id"]}</div>
                  </th>
                  <th
                    class="font-weight-bold"
                    style={{ width: "200px" }}
                    scope="col">
                    <div className="th-container">{lang["case name"]}</div>
                  </th>
                  <th
                    class="font-weight-bold"
                    style={{ width: "480px" }}
                    scope="col">
                    <div className="th-container">{lang["content"]}</div>
                  </th>
                  <th
                    class="font-weight-bold"
                    style={{ width: "220px" }}
                    scope="col">
                    <div className="th-container">{lang["date create"]}</div>
                  </th>
                  <th
                    class="font-weight-bold align-center"
                    style={{ width: "100px" }}
                    scope="col">
                    <div className="th-container">{lang["status"]}</div>
                  </th>
                  <th
                    class="font-weight-bold align-center"
                    style={{ width: "100px" }}>
                    <div className="th-container">{lang["log.action"]}</div>
                  </th>
                  {/* <th class="font-weight-bold align-center" style={{ width: "350px" }} scope="col">Thao tác</th> */}
                </tr>
                <tr class="input-search-cus">
                  <th style={{ borderBottom: "0.5px solid #dee2e6" }}></th>
                  <th style={{ borderBottom: "0.5px solid #dee2e6" }}>
                    <input
                      type="search"
                      className="form-control "
                      // value={
                      //   inputValues["CompanyName"]||""
                      // }
                      onChange={(e) => handleInputChange("CID", e.target.value)}
                      onKeyDown={handleKeyDown}
                    />
                  </th>
                  <th style={{ borderBottom: "0.5px solid #dee2e6" }}>
                    <input
                      type="search"
                      className="form-control "
                      // value={
                      //   inputValues["CompanyName"]||""
                      // }
                      onChange={(e) => handleInputChange("CN", e.target.value)}
                      onKeyDown={handleKeyDown}
                    />
                  </th>
                  <th style={{ borderBottom: "0.5px solid #dee2e6" }}>
                    <input
                      type="search"
                      className="form-control "
                      onChange={(e) => handleInputChange("CT", e.target.value)}
                      onKeyDown={handleKeyDown}
                    />
                  </th>
                  <th style={{ borderBottom: "0.5px solid #dee2e6" }}>
                    <input
                      type="search"
                      className="form-control "
                      // value={
                      //   inputValues["TotalCase"] || ""
                      // }
                      onChange={(e) => handleInputChange("T1", e.target.value)}
                      onKeyDown={handleKeyDown}
                    />
                  </th>
                  <th style={{ borderBottom: "0.5px solid #dee2e6" }}>
                    <input
                      type="search"
                      className="form-control "
                      // value={
                      //   inputValues["TotalProcessing"] || ""
                      // }
                      onChange={(e) => handleInputChange("ST", e.target.value)}
                      onKeyDown={handleKeyDown}
                    />
                  </th>

                  <th
                    class="align-center"
                    onClick={handleSearchClick}
                    style={{
                      minWidth: "100px",
                      borderBottom: "0.5px solid #dee2e6",
                    }}>
                    <i
                      class={`fa fa-search size-24 pointer mb-2 ${
                        isActive ? "icon-active" : ""
                      }`}
                      title={lang["search"]}></i>
                  </th>
                </tr>
              </thead>
              <tbody>
                {loading && current && current.length > 0 ? (
                  <tr>
                    <td
                      class=""
                      colspan={`${apiDataName?.length + 2}`}
                      style={{ textAlign: "center" }}>
                      <div>
                        <img
                          width={48}
                          className="mb-1"
                          src="/images/icon/load.gif"
                          alt="Loading..."></img>
                      </div>
                    </td>
                  </tr>
                ) : (
                  <>
                    {current && current.length > 0 ? (
                      mappedcurrent.map((row, index) => {
                        if (row) {
                          return (
                            <tr key={row.Id}>
                              <td class="align-center">{index + 1}</td>
                              <td>
                                {/**Case ID*/}
                                {row.col1}
                              </td>
                              <td>
                                {/**Tên case*/}
                                <Tooltip title={row.col2}>
                                  <span>T{TextAbstract(row.col2, 25)}</span>
                                </Tooltip>
                              </td>
                              <td>
                                {/**Case content*/}
                                <Tooltip title={row.col3}>
                                  <span>T{TextAbstract(row.col3, 70)}</span>
                                </Tooltip>
                              </td>
                              <td>
                                {/**Ngày*/}
                                {row.col4}
                              </td>
                              <td>
                                {/**Trạng thái*/}
                                {getColorStatus(row.col5)}
                              </td>
                              <td
                                class="align-center"
                                style={{ width: "100px" }}>
                                {checkDetail && (
                                  <i
                                    className="fa fa-eye size-24 pointer icon-view"
                                    onClick={() => viewpageSample(row.col1)}
                                    title={lang["viewdetail"]}></i>
                                )}
                              </td>
                            </tr>
                          );
                        } else {
                          return null;
                        }
                      })
                    ) : (
                      <tr>
                        <td
                          class=""
                          colspan={`${apiDataName?.length + 2}`}
                          style={{ textAlign: "center" }}>
                          <div>{lang["not found"]}</div>
                        </td>
                      </tr>
                    )}
                    {/* <tr>
                <td style={{ height: lastRowFitHeight({}) }}></td>
              </tr> */}
                  </>
                )}
              </tbody>
            </table>
          </div>
        </div>

        {/* <p>
          {
            data.length > 0
              ? `${lang["show"]} ${
                  filteredData.length > 0 ? indexOfFirst + 1 : 0
                } - ${Math.min(indexOfLast, filteredData.length)} ${
                  lang["of"]
                } ${Math.min(data.length, filteredData.length)} ${
                  lang["results"]
                }`
              : null
            // <p> K có data</p>
          }
        </p>

        <nav aria-label="Page navigation example">
          <ul className="pagination mb-0">
            {/* Nút đến trang đầu *
            <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
              <button className="page-link" onClick={() => paginate(1)}>
                &#8810;
              </button>
            </li>
            {/* Nút đến trang trước *
            <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
              <button
                className="page-link"
                onClick={() => paginate(currentPage - 1)}>
                &laquo;
              </button>
            </li>
            {/* Hiển thị số trang *
            {Array(totalPages)
              .fill()
              .map((_, index) => {
                if (
                  index + 1 === currentPage ||
                  (index + 1 >= currentPage - 1 && index + 1 <= currentPage + 1)
                ) {
                  return (
                    <li
                      key={index}
                      className={`page-item ${
                        currentPage === index + 1 ? "active" : ""
                      }`}>
                      <button
                        className="page-link"
                        onClick={() => paginate(index + 1)}>
                        {index + 1}
                      </button>
                    </li>
                  );
                }
              })}
            {/* Nút đến trang sau *
            <li
              className={`page-item ${
                currentPage === totalPages || filteredData.length === 0
                  ? "disabled"
                  : ""
              }`}>
              <button
                className="page-link"
                onClick={() => paginate(currentPage + 1)}>
                &raquo;
              </button>
            </li>
            {/* Nút đến trang cuối *
            <li
              className={`page-item ${
                currentPage === totalPages || filteredData.length === 0
                  ? "disabled"
                  : ""
              }`}>
              <button
                className="page-link"
                onClick={() => paginate(totalPages)}>
                &#8811;
              </button>
            </li>
          </ul>
        </nav> */}
      </div>
      <div class="bottom-div">
        <Flex align="center" gap="small" justify="center">
          <Pagination
            total={data.length}
            showTotal={(total, range) =>
              `${range[0]}-${range[1]} of ${total} items`
            }
            onChange={(value) => {
              paginate(value);
            }}
            defaultPageSize={rowsPerPage}
            defaultCurrent={1}
            showSizeChanger={false}
          />
        </Flex>
      </div>
    </>
  );
}

export default Active_Helpdesk;
