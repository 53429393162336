import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Swal from "sweetalert2";

import { Home } from "./dashboard";
import { Home2 } from "./dashboard";
import { Import } from "./import-data";
import { Navigation, PageNotFound } from "./navigations";
import { Login, SignUp, SignOut, ChangePassword } from "./auth";
import { Settings } from "./settings";
import { ListUser, Profile, Permission, PermissionDetail } from "./users";
import { Logs } from "./logs";
import { Tables, Field, UpdateField } from "./tables";
import { Diagram } from "./diagram";
import Active_Key from "./active_key/active";
import { Fetch, InputPost, InputPut, ImportData, Detail } from "./page";
import { Active_Helpdesk, Table_Key, Chart_HelpDesk } from "./page/step";
import "../css/index.scss";
import { SiteMap } from "./site-map";
import Layout_Detail from "./page/layout/layout_detail";
import FeedBack from "./page/layout_feedback/feedback";
import Dashboard from "./page/layout_admin_dashboard";
import KeyDetail from "./page/layout_admin_dashboard/CompanyDetail/KeysDetail";
import CaseDetail from "./page/layout_admin_dashboard/CompanyDetail/CaseDetail";
import CaseProcessing from "./page/layout_admin_dashboard/CompanyDetail/CaseProcessing";
import CaseDone from "./page/layout_admin_dashboard/CompanyDetail/CaseDone";
import CaseFailed from "./page/layout_admin_dashboard/CompanyDetail/CaseFailed";

import KeyDetailView from "./page/layout_admin_dashboard/CompanyDetail/KeysDetail/pageKeyDetail";
import CaseDetailView from "./page/layout_admin_dashboard/CompanyDetail/CaseDetailViewer";
function App() {
  const dispatch = useDispatch();
  const __token = localStorage.getItem("_token");
  const [_token, setToken] = useState(__token);

  const { lang, proxy, auth, pages, socket, functions } = useSelector(
    (state) => state
  );
  const check = pages[0]?.url;

  const defaultValue = {
    username: "",
    fullname: "",
    role: "",
    email: "",
    phone: "",
    avatar: "",
  };
  // console.log(_token)
  async function updateToken() {
    try {
      // console.log(46, _token)
      const newToken = await functions.refreshToken(proxy(), _token);
      if (newToken) {
        // Lưu trữ token mới
        // localStorage.removeItem('_token')
        setToken(newToken);
        localStorage.setItem("_token", JSON.stringify(newToken));

        // console.log(50, newToken);
        // Cập nhật state hoặc context nếu cần
        // updateTokenState(newToken);

        // Optional: Kiểm tra ngày hết hạn của token mới
        const expirationDate = functions.getTokenExpirationDate(newToken);
        // console.log('Ngày hết hạn của token mới:', expirationDate);
      } else {
        // console.error("Không nhận được token mới");
      }
    } catch (error) {
      // console.error("Error refreshing token:", error);
    }
  }

  // useEffect(() => {
  //   // Kiểm tra và làm mới token mỗi 30 phút
  //   const intervalId = setInterval(updateToken, 5000); // 1800000 ms = 30 phút
  //   // return () => clearInterval(intervalId);

  // }, [_token]);

  // useEffect(() => {
  //   const token = localStorage.getItem("_token") ? localStorage.getItem("_token") : ""
  //   console.log("TOKEN CHANGE: ", token.slice(token.length - 20, token.length))
  // }, [_token])

  const expirationDate = functions.getTokenExpirationDate(_token);
  // console.log('Ngày hết hạn của token (hiện tại):', expirationDate);

  useEffect(() => {
    const specialURLs = ["/login", "/signup", "/signout"];
    const url = window.location.pathname;
    const _token = localStorage.getItem("_token");
    const stringifiedUser = localStorage.getItem("user");
    const user = stringifiedUser ? JSON.parse(stringifiedUser) : defaultValue;

    // console.log(user)
    if (specialURLs.indexOf(url) === -1) {
      if (!_token) {
        window.location = "/login";
      }
      if (user) {
        dispatch({
          branch: "default",
          type: "setAuthInfor",
          payload: {
            user,
          },
        });
      }
    }

    const fetchData = async () => {
      // try {
      const response = await axios.get(proxy() + "/apis/get/ui");
      if (pages && pages.length == 0) {
        const { success, ui } = response.data;
        if (success) {
          dispatch({
            type: "setUIPages",
            payload: { pages: ui.data },
          });
        }
      }
      // } catch (error) {
      //   console.error('Error fetching data:', error);
      // }
    };

    dispatch({
      type: "changeSocketURL",
      payload: proxy(),
    });

    fetchData();
    let socketNotificationReceived = false;

    socket.on("/dipe-production-user-login", ({ username }) => {
      if (user?.username == username) {
        Swal.fire({
          title: lang["notification"],
          text: lang["signout account"],
          icon: "warning",
          showConfirmButton: true,
          confirmButtonText: lang["confirm"],
          customClass: {
            confirmButton: "swal2-confirm my-confirm-button-class",
          },
          allowOutsideClick: false,
        }).then(function (result) {
          if (result.isConfirmed) {
            window.location = "/signout";
          }
        });
        socketNotificationReceived = true;
      }
    });

    window.addEventListener("beforeunload", function (event) {
      if (socketNotificationReceived) {
        localStorage.removeItem("_token");
        localStorage.removeItem("password_hash");
        localStorage.removeItem("user");
        localStorage.setItem("user", JSON.stringify({}));
      }
    });

    socket.on("/dipe-production-import-ui", () => {
      fetchData();
      socket.emit("/dipe-production-reconnect-ui");
    });
  }, []);

  useEffect(() => {
    if (window.location.pathname === "/login") {
      return;
    }

    let isTokenValid = true;
    const checkTokenExpiration = () => {
      const _token = localStorage.getItem("_token");
      // console.log(185, _token);

      if (!_token || !isTokenValid) {
        window.location = "/login";
        return;
      }

      fetch(`${proxy()}/auth/token/check`, {
        headers: {
          Authorization: _token,
        },
      })
        .then((res) => res.json())
        .then((resp) => {
          const { success } = resp;
          if (!success) {
            isTokenValid = false;
            localStorage.removeItem("_token");

            Swal.fire({
              title: lang["Notification"],
              text: lang["expired"],
              icon: "warning",
              showConfirmButton: true,
              confirmButtonText: lang["confirm"],
              allowOutsideClick: false,
            }).then((result) => {
              if (result.isConfirmed) {
                window.location = "/signout";
              }
            });
          }
        });
    };

    const tokenCheckInterval = setInterval(checkTokenExpiration, 86400000);

    return () => {
      clearInterval(tokenCheckInterval);
    };
  }, []);

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Navigate to="/page/thong-ke-thang-nam" />} />
        <Route
          path="/login"
          element={!_token ? <Login /> : <Navigate to="/" />}
        />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/signout" element={<SignOut />} />
        <Route
          path="/changepassword"
          element={<Navigation Child={ChangePassword} />}
        />
        <Route path="/import" element={<Navigation Child={Import} />} />
        <Route path="/diagram_db" element={<Navigation Child={Tables} />} />
        {/* <Route path="/logs" element={<Navigation Child={Logs} />} /> */}
        <Route path="/users" element={<Navigation Child={ListUser} />} />
        <Route path="/users/profile" element={<Navigation Child={Profile} />} />
        <Route path="/privileges" element={<Navigation Child={Permission} />} />
        <Route
          path="/privileges/detail"
          element={<Navigation Child={PermissionDetail} />}
        />
        <Route path="/settings" element={<Navigation Child={Settings} />} />

        <Route path="/active" element={<Navigation Child={Active_Key} />} />
        <Route path="/step" element={<Navigation Child={Active_Helpdesk} />} />
        <Route path="/table_key" element={<Navigation Child={Table_Key} />} />
        <Route
          path="/page/:url/detail-key/:id_str/*"
          element={<Navigation Child={Layout_Detail} />}
        />
        <Route path="/chart" element={<Navigation Child={Chart_HelpDesk} />} />

        <Route path="/sitemap" element={<Navigation Child={SiteMap} />} />
        <Route path="/logs" element={<Navigation Child={Logs} />} />

        <Route
          exac
          path="/page/:url/apis/api/:id_str/input_info"
          element={<Navigation Child={InputPost} />}
        />
        <Route
          path="/page/:url/put/api/:id_str/*"
          element={<Navigation Child={InputPut} />}
        />
        <Route
          path="/page/:url/detail/:id_str/*"
          element={<Navigation Child={Detail} />}
        />
        <Route path="/page/:url" element={<Navigation Child={Fetch} />} />
        <Route
          exac
          path="/page/:url/import"
          element={<Navigation Child={ImportData} />}
        />

        <Route
          exac
          path="/technical"
          element={<Navigation Child={FeedBack} />}
        />

        <Route
          exac
          path="/Dashboard"
          element={<Navigation Child={Dashboard} />}
        />
        <Route
          exac
          path="/CaseDetail"
          element={<Navigation Child={CaseDetail} />}
        />
        <Route
          exac
          path="/KeyDetail"
          element={<Navigation Child={KeyDetail} />}
        />
        <Route
          exac
          path="/CaseProcessing"
          element={<Navigation Child={CaseProcessing} />}
        />
        <Route
          exac
          path="/CaseDone"
          element={<Navigation Child={CaseDone} />}
        />
        <Route
          exac
          path="/CaseFailed"
          element={<Navigation Child={CaseFailed} />}
        />
        <Route
          exac
          path="/KeyDetailViewer/:url/detail-key/:id_str/*"
          element={<Navigation Child={KeyDetailView} />}
        />    
        <Route
          exac
          path="/CaseDetailViewer/:url/detail-case/:id_str/*"
          element={<Navigation Child={CaseDetailView} />}
        />     
        {/* <Route exac path="/diagram" element={ < Navigation Child={Diagram} /> } /> */}
        <Route path="*" element={<PageNotFound />} />
        {/* <Route path="/:url" element={ < Navigation Child={Fetch} /> } />        
        <Route exac path="/page/not/found" element={<PageNotFound />} /> */}
      </Routes>
    </Router>
  );
}

export default App;
